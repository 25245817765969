import { userInstance } from "../api";

const getUserData = ({
  gemeindeNr,
  objektId,
}: {
  gemeindeNr: number;
  objektId: number;
  signal?: AbortSignal;
}) => {
  return userInstance
    .get(`/stammdaten`, {
      params: {
        gemeindeNr,
        objektId,
      },
    })
    .then((res) => res.data);
};

const Stammdaten = {
  get: {
    getUserData,
  },
};

export default Stammdaten;
