import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";

export interface IEigenkompostiererBase {
  place?: string;
  street: string;
  homeNumber: string;
  hausNrZusatz: string;
  postCode: string;
  userName: string;
  userSurname: string;
  message: string;
  bookingCode?: string;
  placePart?: string;
  email: string;
  phoneNumber: string;
}

export interface IEigenkompostiererResponseBody extends IEigenkompostiererBase {
  id: string;
  createdAt: string;
  status: string;
  history?: {
    id: string;
    createdAt: string;
    status: string;
    email: string;
    firstName?: string;
    lastName?: string;
  }[];
}

export interface IEigenkompostierer {
  admin: {
    totalCount: number;
    eigenkompostiererData: IEigenkompostiererResponseBody[];
    eigenkompostiererItem: IEigenkompostiererResponseBody | null;
    isEigenkompostiererItemDataLoading: boolean;
  };
  client: {
    cityParts: {
      id: string;
      orteilBezeichnung: string;
      ortsteilNummer: number;
      ortsteilBezirk: string;
      gemeindeNummer: string;
    }[];
    streets: {
      id: string;
      gemeindeNummer: number;
      strasseBezeichnung: string;
      ortsteilNummer?: string;
    }[];
  };
}

const initialState: IEigenkompostierer = {
  client: {
    cityParts: [],
    streets: [],
  },
  admin: {
    totalCount: 0,
    eigenkompostiererData: [],
    eigenkompostiererItem: null,
    isEigenkompostiererItemDataLoading: false,
  },
};

export const createEigenkompostiererItem = createAsyncThunk(
  "eigenkompostierer/client/createEigenkompostiererItem",
  async (data: IEigenkompostiererBase, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      await API.eigenkompostierer.post.create(data);
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getAdminEigenkompostierer = createAsyncThunk(
  "behaeltergemeinschaft/admin/getAdminBehaeltergemeinschaft",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
      endDate,
      startDate,
      status,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      startDate?: Date | null | string;
      endDate?: Date | null | string;
      status?: string[];
      signal: AbortSignal;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data = API.eigenkompostierer.get.getAdminEigenkompostiererData({
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        signal,
        endDate,
        startDate,
        status,
      });

      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getAdminEigenkompostiererItemById = createAsyncThunk(
  "eigenkompostierer/admin/getAdminEigenkompostiererItemById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        await API.eigenkompostierer.get.getAdminEigenkompostiererItemById(id);

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const updateAdminEigenkompostiererItemById = createAsyncThunk(
  "eigenkompostierer/admin/updateAdminEigenkompostiererItemById",
  async ({ id, status }: { id: string; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        await API.eigenkompostierer.put.updateAdminEigenkompostiererItemById({
          id,
          status,
        });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getCalwEigenkompostiererStreetsAndPlaces = createAsyncThunk(
  "reclamation/client/getCalwEigenkompostiererStreetsAndPlaces",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data =
        await API.eigenkompostierer.get.getEigenkompostiererDataCalw();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const changeItemsStatuses = createAsyncThunk(
  "eigenkompostierer/admin/changeItemsStatuses",
  async ({ ids, status }: { ids: string[]; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));

      const data = await API.eigenkompostierer.put.changeItemsStatuses({
        ids,
        status,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

const eigenkompostiererSlice = createSlice({
  name: "eigenkompostierer",
  initialState,
  reducers: {
    clearAdminEigenkompostierer: (state) => {
      state.admin.totalCount = 0;
      state.admin.eigenkompostiererData = [];
    },
    clearAdminEigenkompostiererItem: (state) => {
      state.admin.eigenkompostiererItem = null;
      state.admin.isEigenkompostiererItemDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminEigenkompostierer.pending, (state) => {});
    builder.addCase(getAdminEigenkompostierer.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.totalCount = action.payload.totalCount;
        state.admin.eigenkompostiererData = [...action.payload.data];
      }
    });
    builder.addCase(getAdminEigenkompostierer.rejected, (state) => {});
    builder.addCase(getAdminEigenkompostiererItemById.pending, (state) => {
      state.admin.isEigenkompostiererItemDataLoading = true;
    });
    builder.addCase(
      getAdminEigenkompostiererItemById.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.eigenkompostiererItem = action.payload;
        }
        state.admin.isEigenkompostiererItemDataLoading = false;
      }
    );
    builder.addCase(getAdminEigenkompostiererItemById.rejected, (state) => {
      state.admin.isEigenkompostiererItemDataLoading = false;
    });
    builder.addCase(
      updateAdminEigenkompostiererItemById.pending,
      (state) => {}
    );
    builder.addCase(
      updateAdminEigenkompostiererItemById.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.eigenkompostiererItem = action.payload;
        }
      }
    );
    builder.addCase(
      updateAdminEigenkompostiererItemById.rejected,
      (state) => {}
    );
    builder.addCase(
      getCalwEigenkompostiererStreetsAndPlaces.pending,
      (state) => {}
    );
    builder.addCase(
      getCalwEigenkompostiererStreetsAndPlaces.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.client.cityParts = action.payload.cityParts;
          state.client.streets = action.payload.streets as any;
        }
      }
    );
    builder.addCase(
      getCalwEigenkompostiererStreetsAndPlaces.rejected,
      (state) => {}
    );
    builder.addCase(changeItemsStatuses.pending, (state) => {});
    builder.addCase(changeItemsStatuses.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.eigenkompostiererData = action.payload;
      }
    });
    builder.addCase(changeItemsStatuses.rejected, (state) => {});
  },
});

export const { clearAdminEigenkompostierer, clearAdminEigenkompostiererItem } =
  eigenkompostiererSlice.actions;

export const eigenkompostiererReducer = eigenkompostiererSlice.reducer;
