import React, { useEffect, useState } from "react";
import { SortDescriptor } from "@progress/kendo-data-query";
import { useAppDispatch, useAppSelector } from "../../store";
import { getAdminBehaeltergemeinschaft } from "../../store/slices/behaeltergemeinschaft";
import {
  AdminContentWrapper,
  AdminHeader,
  TableLastItem,
  Loader,
  TableGrid,
  AdminCheckbox,
} from "../../components";
import { format } from "date-fns";
import {
  GridCellProps,
  GridColumn,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import appConfig from "../../util/appConfig";
import { de } from "date-fns/locale";

export const statuses: { [key: string]: string } = {
  accepted: "Fertig",
  declined: "Storniert",
  inWork: "Offen",
};

const AdminBehaeltergemeinschaft = () => {
  const dispatch = useAppDispatch();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { behaeltergemeinschaftData, totalCount } = useAppSelector(
    (state) => state.behaeltergemeinschaft.admin
  );

  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end || end === null) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };

  const changeType = (st: string[]) => {
    setType([...st]);
  };

  const setAcceptedStatus = () => {
    // dispatch(
    //   changeItemsStatuses({
    //     ids: behaeltergemeinschaftData.map((el) => el.id),
    //     status: "accepted",
    //   })
    // );
  };

  const generateTypeOptions = (type: string) => {
    switch (type) {
      case "calw": {
        return [
          { label: "Aufhebung", value: "Aufhebung" },
          { label: "Anmeldung", value: "Anmeldung" },
        ];
      }
      default: {
        return [];
      }
    }
  };

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = behaeltergemeinschaftData.map((i) => i.id);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: string) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const FirstItem = (props: GridCellProps) => {
    return (
      <td
        // className="k-command-cell"
        style={
          {
            // backgroundColor: 'white',
          }
        }
      >
        <AdminCheckbox
          checked={selectedItems.includes(props.dataItem.id)}
          changeHandler={() => {
            selectItemHandler(props.dataItem.id);
          }}
        />
      </td>
    );
  };
  const FirstHeaderItem = (props: GridHeaderCellProps) => {
    return (
      <div
        style={{
          margin: "0",
          padding: "0",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          // border: "none",
          // backgroundColor: "rgba(0, 0, 0, 0.02) !important",
        }}
        // className={style.qwe}
      >
        <AdminCheckbox
          checked={allItemsSelected}
          changeHandler={allItemsSelectHandler}
        />
      </div>
    );
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminBehaeltergemeinschaft({
        direction: sort[0]?.dir,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        signal,
        startDate: startDate ? new Date(startDate).toUTCString() : startDate,
        endDate: endDate ? new Date(endDate).toUTCString() : endDate,
        status,
        type,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status, type]);

  const selectorOptions = [
    {
      placeholder: "Bitte Status wählen",
      isActive: ["calw"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: [
        { label: statuses.accepted, value: "accepted" },
        { label: statuses.inWork, value: "inWork" },
        { label: statuses.declined, value: "declined" },
      ].filter((el) => !status.includes(el.value)),
      value: status.map((el) => {
        return {
          value: el,
          label: statuses[el],
        };
      }),
      onChange: changeStatuses,
    },
    {
      placeholder: "Bitte Type wählen",
      isActive: ["calw"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: generateTypeOptions(appConfig.logo),
      value: type.map((el) => {
        return { value: el, label: el };
      }),
      onChange: changeType,
    },
  ];

  return (
    <div>
      <AdminHeader pageTitle={"Behältergemeinschaft"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={behaeltergemeinschaftData.map((el) => {
            return {
              ...el,
              createdAt: el.createdAt
                ? format(new Date(el.createdAt), "dd.MM.yyyy, HH:mm")
                : "",
              owner: `${el.ownerName}, ${el.ownerSurname} `,
              couser: `${el.couserName}, ${el.couserSurname} `,
              status: statuses[el.status],
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          items={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "ownerBookingCode", title: "Kassenzeichen" },
            {
              field: "owner",
              title: "Tonnenbesitzer",
            },
            { field: "couser", title: "Tonnen-Mitnutzer" },
            { field: "type", title: "Typ" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={totalCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          onExcelExportClickHandler={setAcceptedStatus}
          selectorOptions={selectorOptions}
          withExcelExport={["calw"].includes(appConfig.logo)}
          excelExportData={behaeltergemeinschaftData
            .filter((el) => selectedItems.includes(el.id))
            .map((el) => {
              return {
                Status: el?.status ? statuses[el?.status] : "",
                "Datum (Meldung)": el?.createdAt
                  ? format(new Date(el?.createdAt), "dd.MM.yyyy, HH:mm", {
                      locale: de,
                    })
                  : "",
                Besitzername: `${el?.ownerSurname}, ${el?.ownerName}`,
                Besitzerstraße: `${el?.ownerStreet} ${el?.ownerHomeNumber} ${
                  el?.ownerHomeNumberZusatz
                    ? " " + el?.ownerHomeNumberZusatz
                    : ""
                }`,
                Besitzerort: `${el?.ownerPostcode ? el.ownerPostcode : ""} ${
                  el?.ownerPlacePart
                }`,
                Besitzerkassenzeichen: `${el?.ownerBookingCode}`,
                "Besitzer E-Mail": `${el?.email}`,
                Besitzertelefonnummer: `${el?.phoneNumber}`,
                Mitnutzername: `${el?.couserSurname}, ${el?.couserName}`,
                Mitnutzerstraße: `${el?.couserStreet} ${el?.couserHomeNumber} ${
                  el?.couserHomeNumberZusatz
                    ? " " + el?.couserHomeNumberZusatz
                    : ""
                }`,
                Mitnutzerort: `${el?.couserPostcode ? el.couserPostcode : ""} ${
                  el?.couserPlacePart
                }`,
                Mitnutzerkassenzeichen: `${
                  el?.couserBookingCode ? el.couserBookingCode : ""
                }`,
                Typ: `${el?.type ? el.type : ""}`,
                Tonne: `${(el?.ownerTrashBinData && el?.ownerTrashBinData.length
                  ? el?.ownerTrashBinData
                  : []
                )
                  .map((i: any) => {
                    return `${i?.bin ? i?.bin : ""}, Nr. ${
                      i?.binNumber ? i?.binNumber : ""
                    }`;
                  })
                  .toString()
                  .replaceAll(",", "; ")}`,
              };
            })}
          excelExportItem={[
            {
              field: "Status",
              title: "Status",
            },
            {
              field: "Datum (Meldung)",
              title: "Datum (Meldung)",
            },
            {
              field: "Besitzername",
              title: "Besitzername",
            },
            {
              field: "Besitzerstraße",
              title: "Besitzerstraße",
            },
            {
              field: "Besitzerort",
              title: "Besitzerort",
            },
            {
              field: "Besitzerkassenzeichen",
              title: "Besitzerkassenzeichen",
            },
            {
              field: "Besitzer E-Mail",
              title: "Besitzer E-Mail",
            },
            {
              field: "Besitzertelefonnummer",
              title: "Besitzertelefonnummer",
            },
            {
              field: "Mitnutzername",
              title: "Mitnutzername",
            },
            {
              field: "Mitnutzerstraße",
              title: "Mitnutzerstraße",
            },
            {
              field: "Mitnutzerort",
              title: "Mitnutzerort",
            },
            {
              field: "Mitnutzerkassenzeichen",
              title: "Mitnutzerkassenzeichen",
            },
            {
              field: "Typ",
              title: "Typ",
            },
            {
              field: "Tonne",
              title: "Tonne",
            },
          ]}
          firstItem={
            ["calw"].includes(appConfig.logo) ? (
              <GridColumn
                cell={FirstItem}
                width="40px"
                headerCell={FirstHeaderItem}
              />
            ) : null
          }
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminBehaeltergemeinschaft;
