import React from "react";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import appConfig from "../../util/appConfig";
import awlFullLogo from "../../assets/images/logos/awlFullLogo.svg";
import bkFullLogo from "../../assets/images/logos/bkFullLogo.svg";
import ebwoFullLogo from "../../assets/images/logos/ebwoLogo.jpg";
import sensisFullLogo from "../../assets/images/logos/sensisFullLogo.svg";
import lraLogo from "../../assets/images/logos/lraLogo.svg";
import calwLogo from "../../assets/images/logos/calwLogo.svg";
import { useAppSelector } from "../../store";
import { format } from "date-fns";
import packageJson from "../../../package.json";

export const Footer = () => {
  const { isMobile } = useAppSelector((state) => state.app.client);

  const logo = (logo: string) => {
    switch (logo) {
      case "awl": {
        return awlFullLogo;
      }
      case "bk": {
        return bkFullLogo;
      }
      case "sensis": {
        return sensisFullLogo;
      }
      case "ebwo": {
        return ebwoFullLogo;
      }
      case "lra": {
        return lraLogo;
      }
      case "calw": {
        return calwLogo;
      }
      default: {
        return sensisFullLogo;
      }
    }
  };

  let links = {
    protectDate: "",
    imprint: "",
    contact: "",
  };

  const generateLinks = (type: string) => {
    switch (type) {
      case "awl": {
        links.protectDate = "https://www.awl-neuss.de/footer/datenschutz.html";
        links.imprint = "https://www.awl-neuss.de/footer/impressum.html";
        links.contact = "https://www.awl-neuss.de/footer/kontakt.html";
        break;
      }
      case "bk": {
        links.protectDate = "https://app.awb-bad-kreuznach.de/impressum";
        links.imprint = "https://app.awb-bad-kreuznach.de/impressum";
        links.contact = "https://app.awb-bad-kreuznach.de/kontakt2";
        break;
      }
      case "ebwo": {
        links.protectDate =
          "https://www.worms.de/neu-de/service/datenschutz.php#anchor_72501692_Accordion-Homepage-Entsorgungs--und-Baubetrieb-AoeR--ebwo-de";
        links.imprint = "https://www.ebwo.de/de/impressum/";
        break;
      }
      default: {
        return links;
      }
    }
  };

  generateLinks(appConfig.logo);

  return (
    <section>
      <div className={style.footer_wrapper}>
        <img
          className={`${style.logo} ${
            appConfig.logo === "ebwo" ? style.ebwoLogo : ""
          }`}
          src={logo(appConfig.logo)}
          alt={appConfig.logo}
        />
        <nav
          className={
            !isMobile ? style.nav_container : style.mobileNav_container
          }
        >
          <div className={style.nav_link}>
            {!["sensis", "ebwo", "lra"].includes(appConfig.logo) ? (
              <a href={links.protectDate} rel="noreferrer" target="_blank">
                Datenschutz
              </a>
            ) : (
              <Link
                to="/privacy"
                onClick={() => {
                  window.scrollTo({
                    top: -1000,
                  });
                }}
              >
                Datenschutz
              </Link>
            )}
          </div>
          <div className={style.nav_link}>
            {!["sensis", "lra"].includes(appConfig.logo) ? (
              <a href={links.imprint} rel="noreferrer" target="_blank">
                Impressum
              </a>
            ) : (
              <Link
                to="/impressum"
                onClick={() => {
                  window.scrollTo({
                    top: -1000,
                  });
                }}
              >
                Impressum
              </Link>
            )}
          </div>
          {appConfig.logo !== "ebwo" ? (
            <>
              <div className={style.nav_link}>
                {!["sensis", "lra"].includes(appConfig.logo) ? (
                  <a href={links.contact} rel="noreferrer" target="_blank">
                    Kontakt
                  </a>
                ) : (
                  <Link to="/contact">Kontakt</Link>
                )}
              </div>
              {!["lra"].includes(appConfig.logo) ? (
                <div className={`${style.nav_link} ${style.faq_link}`}>
                  <a href="/#faq">FAQS</a>
                </div>
              ) : null}
            </>
          ) : null}
        </nav>
        <div className={style.copyright}>
          ©Copyright {format(new Date(), "yyyy")} {appConfig.title}
        </div>
      </div>
      {/* <div
        style={{
          textAlign: "center",
        }}
        className={style.copyright}
      >
        {packageJson.version}
      </div> */}
    </section>
  );
};
