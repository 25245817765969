import { userToken } from "./../../util/userToken";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../api/api";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import { NavigateFunction } from "react-router-dom";
import appConfig from "../../util/appConfig";

interface IAdminState {
  email: string;
  role: string;
  isTokenValid: boolean | undefined;
  isDataLoading: boolean;
  isAdminLoaderActive: boolean;
  streetsData: { street: string }[];
  hasStreetsDataNextPage: boolean;
  // streets: {
  //   strasseBezeichnung: string;
  //   strasseNummer: number;
  //   ignored: boolean;
  // }[];
  ignoredStreets: {
    strasseBezeichnung: string;
    strasseNummer: number;
    ignore: "active" | "partially" | "ignore";
    blockedHomeNumbers: { from: string; to: string; _id: string }[];
  }[];
  activeStreets: {
    strasseBezeichnung: string;
    strasseNummer: number;
    ignore: "active" | "partially" | "ignore";
    blockedHomeNumbers: { from: string; to: string; _id: string }[];
  }[];
  activeWhiteListOfStreets: {
    strasseBezeichnung: string;
    strasseNummer: number;
    whiteListIgnore: "active" | "partially" | "ignore";
    whiteListHomeNumbers: { from: string; to: string; _id: string }[];
  }[];
  ignoredWhiteListOfStreets: {
    strasseBezeichnung: string;
    strasseNummer: number;
    whiteListIgnore: "active" | "partially" | "ignore";
    whiteListHomeNumbers: { from: string; to: string; _id: string }[];
  }[];
  activeGreyListOfStreets: {
    strasseBezeichnung: string;
    strasseNummer: number;
    greyListIgnore: "active" | "partially" | "ignore";
    greyListHomeNumbers: { from: string; to: string; _id: string }[];
  }[];
  ignoredGreyListOfStreets: {
    strasseBezeichnung: string;
    strasseNummer: number;
    greyListIgnore: "active" | "partially" | "ignore";
    greyListHomeNumbers: { from: string; to: string; _id: string }[];
  }[];
  isBlockedStreetsContentLoading: boolean;
}

const initialState: IAdminState = {
  email: "",
  role: "",
  isTokenValid: undefined,
  isDataLoading: false,
  isAdminLoaderActive: false,
  streetsData: [],
  hasStreetsDataNextPage: true,
  ignoredStreets: [],
  activeStreets: [],
  activeGreyListOfStreets: [],
  activeWhiteListOfStreets: [],
  ignoredGreyListOfStreets: [],
  ignoredWhiteListOfStreets: [],
  // streets: []
  isBlockedStreetsContentLoading: false,
};

export const loginAdmin = createAsyncThunk(
  "admin/login",
  async (
    obj: { email: string; password: string; navigate: NavigateFunction },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const { token, role } = await await API.admin.post.adminLogin(
        obj.email,
        obj.password
      );
      thunkApi.dispatch(setIsLoading(false));
      userToken.setAdminToken(token);
      obj.navigate(
        `/admin/${
          appConfig.logo === "viersen" ? "sepa" : "sperrmuell-anmeldungen"
        }`
      );
      return { role };
    } catch (error: any) {
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const checkAdminToken = createAsyncThunk(
  "admin/checkToken",
  async (
    obj: { token: string; navigate: NavigateFunction; path: string },
    thunkApi
  ) => {
    try {
      // thunkApi.dispatch(setIsLoading(true));
      const { isValid, role } = await API.admin.get.checkAdminToken(obj.token);
      // thunkApi.dispatch(setIsLoading(false));
      if (isValid) {
        obj.navigate(obj.path);
      }
      if (!isValid) {
        userToken.removeAdminToken();
      }
      return { isValid, role };
    } catch (error) {
      // thunkApi.dispatch(setIsLoading(false));
      // thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getStreetData = createAsyncThunk(
  "admin/getStreetData",
  async (page: number, thunkApi) => {
    try {
      const article = await API.admin.get.getStreets(page);

      return article;
    } catch (error: any) {
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getStreetsWithIgnoreFlag = createAsyncThunk(
  "admin/getStreetsWithIgnoreFlag",
  async (_, thunkAPI) => {
    try {
      const streets = await API.admin.get.getStreetsWithIgnoreFlag();
      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);
export const updateStreets = createAsyncThunk(
  "admin/updateStreets",
  async (
    // data: { ids: string[]; ignore: "active" | "partially" | "ignore" },
    data: {
      id: string;
      ignore: "active" | "partially" | "ignore";
      blockedHomeNumbers?: string[];
    }[],
    thunkAPI
  ) => {
    try {
      const streets = await API.admin.put.updateStreets(data);
      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const updateOneStreet = createAsyncThunk(
  "admin/updateOneStreet",
  async (
    data: {
      id: string;
      ignore: "active" | "partially" | "ignore";
      blockedHomeNumbers?: { from: string; to: string }[];
    },
    thunkAPI
  ) => {
    try {
      const streets = await API.admin.post.updateOneStreet(data);
      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const getWhiteListOfStreets = createAsyncThunk(
  "admin/getWhiteListOfStreets",
  async (_, thunkAPI) => {
    try {
      const streets = await API.admin.get.getWhiteListStreets();

      return streets;
    } catch (error) {
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const setWhiteListOfStreets = createAsyncThunk(
  "admin/setWhiteListOfStreets",
  async (
    // data: { ids: string[]; ignore: "active" | "partially" | "ignore" },
    data: {
      id: string;
      ignore: "active" | "partially" | "ignore";
      homeNumbers?: { from: string; to: string }[];
    },
    thunkAPI
  ) => {
    try {
      const streets = await API.admin.post.setWhiteListOfStreets(data);
      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const updateWhiteListStreets = createAsyncThunk(
  "admin/updateWhiteListStreets",
  async (
    // data: { ids: string[]; ignore: "active" | "partially" | "ignore" },
    data: {
      id: string;
      ignore: "active" | "partially" | "ignore";
      whiteListHomeNumbers?: string[];
    }[],
    thunkAPI
  ) => {
    try {
      const streets = await API.admin.put.updateWhiteListStreets(data);
      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const getGreyListOfStreets = createAsyncThunk(
  "admin/getGreyListOfStreets",
  async (_, thunkAPI) => {
    try {
      const streets = await API.admin.get.getGreyListStreets();

      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const setGreyListOfStreets = createAsyncThunk(
  "admin/setGreyListOfStreets",
  async (
    // data: { ids: string[]; ignore: "active" | "partially" | "ignore" },
    data: {
      id: string;
      ignore: "active" | "partially" | "ignore";
      homeNumbers?: { from: string; to: string }[];
    },
    thunkAPI
  ) => {
    try {
      const streets = await API.admin.post.setGreyListOfStreets(data);
      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const updateGreyListStreets = createAsyncThunk(
  "admin/updateGreyListStreets",
  async (
    // data: { ids: string[]; ignore: "active" | "partially" | "ignore" },
    data: {
      id: string;
      ignore: "active" | "partially" | "ignore";
      greyListHomeNumbers?: string[];
    }[],
    thunkAPI
  ) => {
    try {
      const streets = await API.admin.put.updateGreyListStreets(data);
      return streets;
    } catch (error: any) {
      thunkAPI.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminLoader: (state, action: PayloadAction<boolean>) => {
      state.isAdminLoaderActive = action.payload;
    },
    setDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isDataLoading = action.payload;
    },
    clearStreetsData: (state) => {
      state.streetsData = [];
      state.hasStreetsDataNextPage = true;
    },
    clearStreets: (state) => {
      // state.streets = [];
      state.activeStreets = [];
      state.ignoredStreets = [];
      state.activeWhiteListOfStreets = [];
      state.ignoredWhiteListOfStreets = [];
      state.activeGreyListOfStreets = [];
      state.ignoredGreyListOfStreets = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      if (action.payload) {
        state.role = action.payload?.role || "";
      }
    });
    builder.addCase(checkAdminToken.fulfilled, (state, action) => {
      state.isTokenValid = action.payload?.isValid;
      state.role = action.payload?.role ? action.payload?.role : "";
    });
    builder.addCase(getStreetData.pending, (state) => {
      state.isAdminLoaderActive = true;
    });
    builder.addCase(getStreetData.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      if (action.payload) {
        state.streetsData = [...state.streetsData, ...action.payload];
        if (action.payload.length < 100) {
          state.hasStreetsDataNextPage = false;
        }
      }
    });
    builder.addCase(getStreetData.rejected, (state) => {
      state.isAdminLoaderActive = false;
    });
    builder.addCase(getStreetsWithIgnoreFlag.pending, (state) => {
      state.isAdminLoaderActive = true;
      // state.isDataLoading = true;
      state.isBlockedStreetsContentLoading = true;
    });
    builder.addCase(getStreetsWithIgnoreFlag.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      state.isDataLoading = false;
      state.isBlockedStreetsContentLoading = false;
      if (action.payload) {
        state.ignoredStreets = [
          ...action.payload.filter(
            (i) => i.ignore === "ignore" || i.ignore === "partially"
          ),
        ];
        state.activeStreets = [
          ...action.payload.filter(
            (i) => i.ignore === "active" || i.ignore === "partially"
          ),
        ];
      }
    });
    builder.addCase(getStreetsWithIgnoreFlag.rejected, (state) => {
      state.isAdminLoaderActive = false;
      state.isDataLoading = false;
      state.isBlockedStreetsContentLoading = false;
    });
    builder.addCase(updateStreets.pending, (state) => {
      state.isAdminLoaderActive = true;
    });
    builder.addCase(updateStreets.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      if (action.payload) {
        state.ignoredStreets = [
          ...action.payload.filter(
            (i) => i.ignore === "ignore" || i.ignore === "partially"
          ),
        ];
        state.activeStreets = [
          ...action.payload.filter(
            (i) => i.ignore === "active" || i.ignore === "partially"
          ),
        ];
      }
    });
    builder.addCase(updateStreets.rejected, (state) => {
      state.isAdminLoaderActive = false;
    });
    builder.addCase(updateOneStreet.pending, (state) => {
      state.isAdminLoaderActive = true;
    });
    builder.addCase(updateOneStreet.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      if (action.payload) {
        state.ignoredStreets = [
          ...action.payload.filter(
            (i) => i.ignore === "ignore" || i.ignore === "partially"
          ),
        ];
        state.activeStreets = [
          ...action.payload.filter(
            (i) => i.ignore === "active" || i.ignore === "partially"
          ),
        ];
      }
    });
    builder.addCase(updateOneStreet.rejected, (state) => {
      state.isAdminLoaderActive = false;
    });
    builder.addCase(getWhiteListOfStreets.pending, (state) => {
      state.isAdminLoaderActive = true;
      state.isBlockedStreetsContentLoading = true;
    });
    builder.addCase(getWhiteListOfStreets.fulfilled, (state, action) => {
      if (action.payload) {
        state.ignoredWhiteListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.whiteListIgnore === "ignore" ||
              i.whiteListIgnore === "partially"
          ),
        ];
        state.activeWhiteListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.whiteListIgnore === "active" ||
              i.whiteListIgnore === "partially"
          ),
        ];
      }
      state.isAdminLoaderActive = false;
      state.isBlockedStreetsContentLoading = false;
    });
    builder.addCase(getWhiteListOfStreets.rejected, (state) => {
      state.isAdminLoaderActive = false;
      state.isBlockedStreetsContentLoading = false;
    });
    builder.addCase(getGreyListOfStreets.pending, (state) => {
      state.isAdminLoaderActive = true;
      state.isBlockedStreetsContentLoading = true;
    });
    builder.addCase(getGreyListOfStreets.fulfilled, (state, action) => {
      if (action.payload) {
        state.ignoredGreyListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.greyListIgnore === "ignore" || i.greyListIgnore === "partially"
          ),
        ];
        state.activeGreyListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.greyListIgnore === "active" || i.greyListIgnore === "partially"
          ),
        ];
      }
      state.isAdminLoaderActive = false;
      state.isBlockedStreetsContentLoading = false;
    });
    builder.addCase(getGreyListOfStreets.rejected, (state) => {
      state.isAdminLoaderActive = true;
      state.isBlockedStreetsContentLoading = false;
    });
    builder.addCase(setWhiteListOfStreets.pending, (state) => {
      state.isAdminLoaderActive = true;
    });
    builder.addCase(setWhiteListOfStreets.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      if (action.payload) {
        state.ignoredWhiteListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.whiteListIgnore === "ignore" ||
              i.whiteListIgnore === "partially"
          ),
        ];
        state.activeWhiteListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.whiteListIgnore === "active" ||
              i.whiteListIgnore === "partially"
          ),
        ];
      }
    });
    builder.addCase(setWhiteListOfStreets.rejected, (state) => {
      state.isAdminLoaderActive = false;
    });
    builder.addCase(setGreyListOfStreets.pending, (state) => {
      state.isAdminLoaderActive = true;
    });
    builder.addCase(setGreyListOfStreets.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      if (action.payload) {
        state.ignoredGreyListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.greyListIgnore === "ignore" || i.greyListIgnore === "partially"
          ),
        ];
        state.activeGreyListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.greyListIgnore === "active" || i.greyListIgnore === "partially"
          ),
        ];
      }
    });
    builder.addCase(setGreyListOfStreets.rejected, (state) => {
      state.isAdminLoaderActive = false;
    });
    builder.addCase(updateWhiteListStreets.pending, (state) => {
      state.isAdminLoaderActive = true;
    });
    builder.addCase(updateWhiteListStreets.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      if (action.payload) {
        state.ignoredWhiteListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.whiteListIgnore === "ignore" ||
              i.whiteListIgnore === "partially"
          ),
        ];
        state.activeWhiteListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.whiteListIgnore === "active" ||
              i.whiteListIgnore === "partially"
          ),
        ];
      }
    });
    builder.addCase(updateWhiteListStreets.rejected, (state) => {
      state.isAdminLoaderActive = false;
    });
    builder.addCase(updateGreyListStreets.pending, (state) => {
      state.isAdminLoaderActive = true;
    });
    builder.addCase(updateGreyListStreets.fulfilled, (state, action) => {
      state.isAdminLoaderActive = false;
      if (action.payload) {
        state.ignoredGreyListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.greyListIgnore === "ignore" || i.greyListIgnore === "partially"
          ),
        ];
        state.activeGreyListOfStreets = [
          ...action.payload.filter(
            (i) =>
              i.greyListIgnore === "active" || i.greyListIgnore === "partially"
          ),
        ];
      }
    });
    builder.addCase(updateGreyListStreets.rejected, (state) => {
      state.isAdminLoaderActive = false;
    });
  },
});

export const {
  setAdminLoader,
  clearStreetsData,
  setDataLoading,
  clearStreets,
} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;

//{ strasseBezeichnung: string; strasseNummer: number; whiteListIgnore: "active" | "partially" | "ignore"; whitelistHomeNumbers: { from: string; to: string; _id: string; }[]; }[]
//{ strasseBezeichnung: string; strasseNummer: number; whiteListIgnore: "active" | "partially" | "ignore"; whiteListHomeNumbers: { from: string; to: string; _id: string; }[]; }>[]
