import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  CheckboxV2,
  Loader,
} from "../../components";
import {
  clearAdminBehaelterItem,
  getAdminBehaelterverwaltungById,
  updateStatusById,
} from "../../store/slices/behaelterverwaltung";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { statuses } from "../AdminSperrmuelanmeldungen";
import appConfig from "../../util/appConfig";
import transformHexToRgba from "../../util/transformHexToRgba";
import { requestTypes } from "../AdminBehaelterverwaltung";

const month = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

const AdminBehaelterverwaltungById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { isBehaeltervervaltunItemDataLoading, behaeltervervaltunItem } =
    useAppSelector((state) => state.behaelterverwaltung.admin);

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminBehaelterverwaltungById(itemId));
    }
    return () => {
      dispatch(clearAdminBehaelterItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  const items = [
    {
      title: "",
      items: [
        {
          rowTitle: "Status",
          rowInfo: statuses[behaeltervervaltunItem?.status!],
        },
        behaeltervervaltunItem?.errorMessage
          ? {
              rowTitle: "Fehler Nachricht",
              rowInfo: behaeltervervaltunItem?.errorMessage,
            }
          : {},
        {
          rowTitle: "Erstellungsdatum",
          rowInfo: behaeltervervaltunItem
            ? format(
                new Date(behaeltervervaltunItem?.createdAt),
                "dd.MM.yyyy",
                {
                  locale: de,
                }
              )
            : "",
        },
        {
          rowTitle: "Typ",
          rowInfo: `${
            behaeltervervaltunItem?.requestType
              ? requestTypes[behaeltervervaltunItem?.requestType]
              : behaeltervervaltunItem?.requestType
          }`,
          isLast: true,
        },
        {
          rowTitle: "Objekt ID",
          rowInfo: `${
            behaeltervervaltunItem?.objektId
              ? behaeltervervaltunItem?.objektId
              : ""
          }`,
          isLast: true,
        },
      ],
    },
    {
      title: "Kontaktdaten",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${behaeltervervaltunItem?.usersurname}, ${behaeltervervaltunItem?.username}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: `${
            behaeltervervaltunItem?.phoneNumber
              ? behaeltervervaltunItem?.phoneNumber
              : "-"
          }`,
          isLast: true,
        },
      ],
    },
    {
      title: "Behälterinfo",
      items: [
        {
          rowTitle: "Inventarnummer",
          rowInfo: `${behaeltervervaltunItem?.inventarnr || ""}`,
        },
        {
          rowTitle: "Fraktion",
          rowInfo: `${behaeltervervaltunItem?.behfraktion}`,
        },
        {
          rowTitle: "Volumen",
          rowInfo: `${behaeltervervaltunItem?.behvolumen || ""}`,
        },
        {
          rowTitle: "Tonnenmenge",
          rowInfo: `${behaeltervervaltunItem?.binQuantity || ""}`,
          isActive: ["lra"].includes(appConfig.logo),
        },
        {
          rowTitle: "Saisontonne",
          rowInfo: ``,
          rowCustomItem: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "26px",
              }}
            >
              {behaeltervervaltunItem?.saisonBin?.map((el) => {
                return (
                  <div
                    key={el.bin}
                    style={{
                      display: "flex",
                      // flexWrap: 'wrap',
                      // marginBottom: '16px'
                    }}
                  >
                    {/* Tonne {el.bin}: {el.month.toString().replaceAll(",", ", ")} */}
                    <div
                      style={{
                        flexShrink: 0,
                        marginRight: "20px",
                      }}
                    >
                      Tonne {el.bin}:{" "}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        flexDirection: "row",
                      }}
                    >
                      {month.map((i) => {
                        return (
                          // <span style={{ marginRight: "10px" }}>
                          //   {i}({el.month.includes(i) ? "x" : ""})
                          // </span>
                          <CheckboxV2
                            onChange={() => {}}
                            text={i}
                            key={i}
                            value={el.month.includes(i)}
                            customStyle={{
                              width: "auto",
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          ),
          isActive: ["lra"].includes(appConfig.logo),
        },
        {
          rowTitle: "KAV-Nummer",
          rowInfo: `${
            behaeltervervaltunItem?.kavNumbers?.length
              ? behaeltervervaltunItem?.kavNumbers
                  .toString()
                  .replaceAll(",", ", ")
              : ""
          }`,
        },
        {
          rowTitle: "Prüfungsgrund",
          rowInfo: ``,
          isActive: ["lra"].includes(appConfig.logo),
        },
        {
          rowTitle: "Zusätzliche Info",
          rowInfo: `${
            behaeltervervaltunItem?.additionalInfo
              ? behaeltervervaltunItem?.additionalInfo
              : ""
          }`,
          isLast: true,
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <AdminDetailsPageItemName text="Detailinformationen" />
        {isBehaeltervervaltunItemDataLoading ? null : !behaeltervervaltunItem ? null : (
          <div>
            {items.map((el, idx) => {
              return (
                <AdminDetailInfoItem
                  items={el.items.filter(
                    (i: any) => i.isActive === undefined || i.isActive === true
                  )}
                  title={el.title}
                  key={idx}
                />
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text="Akzeptieren"
                width="200"
                backgroundColor={
                  behaeltervervaltunItem?.status === "accepted"
                    ? transformHexToRgba(appConfig.mainColor, "0.4")
                    : appConfig.mainColor
                }
                disabled={behaeltervervaltunItem?.status === "accepted"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateStatusById({
                        id: itemId,
                        status: "accepted",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={
                  behaeltervervaltunItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                textColor={
                  behaeltervervaltunItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                disabled={behaeltervervaltunItem?.status === "declined"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateStatusById({
                        id: itemId,
                        status: "declined",
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminBehaelterverwaltungById;
