import { IBescheideResponseBody } from "../../store/slices/bescheide";
import { userInstance } from "../api";

const getBescheideData = ({
  belegDatumBis,
  belegDatumVon,
  objektId,
  signal,
}: {
  belegDatumVon?: Date  | null;
  objektId: string;
  belegDatumBis?: Date | null;
  signal?: AbortSignal;
}) => {
  return userInstance
    .get<IBescheideResponseBody[]>("/bescheide", {
      params: {
        belegDatumBis,
        belegDatumVon,
        objektId,
      },
      signal,
    })
    .then((res) => res.data);
};

const Bescheide = {
  get: {
    getBescheideData,
  },
};

export default Bescheide;
