import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
} from "../../components";
import {
  clearAdminZusatzleisungenById,
  getAdminZusatzleistungenById,
  updateStatusById,
} from "../../store/slices/zusatzleistungen";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { statuses } from "../AdminSperrmuelanmeldungen";
import appConfig from "../../util/appConfig";
import transformHexToRgba from "../../util/transformHexToRgba";

const AdminZusatzleisungById = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { isZusatzleistungenItemDataLoading, zusatzleistungenItem } =
    useAppSelector((state) => state.zusatzleistung.admin);

  useEffect(() => {
    if (id) {
      dispatch(getAdminZusatzleistungenById(id));
    }
    return () => {
      dispatch(clearAdminZusatzleisungenById());
      dispatch(setDataLoading(false));
    };
  }, []);

  const items = [
    {
      title: "Detailinformation",
      items: [
        {
          rowTitle: "Status",
          rowInfo: statuses[zusatzleistungenItem?.status!],
        },
        {
          rowTitle: "Erstelungsdatum",
          rowInfo: zusatzleistungenItem
            ? format(new Date(zusatzleistungenItem?.createdAt), "dd.MM.yyyy", {
                locale: de,
              })
            : "",
        },
        {
          rowTitle: "Objekt ID",
          rowInfo: zusatzleistungenItem?.objektid.toString() || "",
        },
      ],
    },
    {
      title: "Kontaktdaten",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${zusatzleistungenItem?.usersurname}, ${zusatzleistungenItem?.username}`,
        },
        {
          rowTitle: "Adresse",
          rowInfo: `${zusatzleistungenItem?.ortsteilbez}, ${zusatzleistungenItem?.gemeindebez}, ${zusatzleistungenItem?.plz}, ${zusatzleistungenItem?.strassebez}, ${zusatzleistungenItem?.hausnr} ${zusatzleistungenItem?.hausnrzusatz}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: `${
            zusatzleistungenItem?.phoneNumber
              ? zusatzleistungenItem?.phoneNumber
              : "-"
          }`,
        },
      ],
    },
    {
      title: "Zusatzleistungen",
      items: [
        {
          rowTitle: "Interne Behälternr.",
          rowInfo: `Zusatzleistungen`,
        },
        ...((zusatzleistungenItem?.data.length
          ? zusatzleistungenItem?.data
          : []
        ).map((el) => {
          return {
            rowTitle: el.behid.toString(),
            rowInfo: ``,
            rowCustomItem: (
              <>
                {el.service.map((i) => {
                  return <div key={i}>1x {i}</div>;
                })}
              </>
            ),
          };
        }) as any),
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <AdminDetailsPageItemName text="Detailinformationen" />
        {isZusatzleistungenItemDataLoading ? null : !zusatzleistungenItem ? null : (
          <div>
            {items.map((el, idx) => {
              return (
                <AdminDetailInfoItem
                  items={el.items}
                  title={el.title}
                  key={idx}
                />
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text="Akzeptieren"
                width="200"
                backgroundColor={
                  zusatzleistungenItem?.status === "accepted"
                    ? transformHexToRgba(appConfig.mainColor, "0.4")
                    : appConfig.mainColor
                }
                marginRight="20"
                disabled={zusatzleistungenItem?.status === "accepted"}
                onClick={() => {
                  if (id) {
                    dispatch(
                      updateStatusById({
                        id,
                        status: "accepted",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={
                  zusatzleistungenItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                textColor={
                  zusatzleistungenItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                disabled={zusatzleistungenItem?.status === "declined"}
                marginRight="20"
                onClick={() => {
                  if (id) {
                    dispatch(
                      updateStatusById({
                        id,
                        status: "declined",
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminZusatzleisungById;
