import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import style from "./style.module.scss";
import "../../../index.scss";
import AdminToken from "../../AdminToken";
import AdminSperrmuelanmeldungen from "../../AdminSperrmuelanmeldungen";
import AdminCalendarData from "../../AdminCalendarData";
import AdminSchadstoffmobilStandorte from "../../AdminSchadstoffmobilStandorte";
import appConfig from "../../../util/appConfig";
import AdminCityParts from "../../AdminCityParts";
import AdminArticles from "../../AdminArticles";
import AdminPortalSettings from "../../AdminPortalSettings";
import AdminCreateUpdateArticle from "../../AdminCreateUpdateArticle";
import BlockedStreet from "../../AdminBlacklistStreets";
import AdminSperrmuelanmeldungInfo from "../../AdminSperrmuelanmeldungInfo";
import AdminCalendarDataItemInfo from "../../AdminCalendarDataItemInfo";
import WhiteListStreets from "../../AdminWhiteListStreets";
import GreyListStreets from "../../AdmimnGreyListStreets";
import AdminMailTracking from "../../AdminMailTracking";
import AdminReclamation from "../../AdminReclamation";
import AdminBehaeltergemeinschaft from "../../AdminBehaeltergemeinschaft";
import AdminEigenkompostierer from "../../AdminEigenkompostierer";
import AdminBehaeltergemeinschaftInfoById from "../../AdminBehaeltergemeinschaftInfoById";
import AdminEigenkompostiererInfoById from "../../AdminEigenkompostiererInfoById";
import AdminReclamationById from "../../AdminReclamationById";
import AdminSepa from "../../AdminSepa";
import AdminSepaById from "../../AdminSepaById";
import AdminEigentuemerwechsel from "../../AdminEigentuemerwechsel";
import AdminEigentuemerwechselById from "../../AdminEigentuemerwechselById";
import AdminBehaelterverwaltung from "../../AdminBehaelterverwaltung";
import AdminBehaelterverwaltungById from "../../AdminBehaelterverwaltungById";
import AdminZusatzleisung from "../../AdminZusatzleisung";
import AdminZusatzleisungById from "../../AdminZusatzleisungById";
import { useAppDispatch } from "../../../store";
import { getAdminNotificationItemsCount } from "../../../store/slices/app";
import AdminUsers from "../../AdminUsers";
import AdminUserById from "../../AdminUserById";

const AdminMain = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAdminNotificationItemsCount());

    const interval = setInterval(() => {
      if (["lra", "calw"].includes(appConfig.logo)) {
        dispatch(getAdminNotificationItemsCount());
      }
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // }
  }, []);

  return (
    <div className={style.wrapper}>
      <Sidebar />
      <div className={style.mainBodyWrapper}>
        <Routes>
          <Route
            path="/sperrmuell-anmeldungen"
            element={<AdminSperrmuelanmeldungen />}
          />
          <Route
            path="/sperrmuell-anmeldungen/:itemId"
            element={<AdminSperrmuelanmeldungInfo />}
          />
          <Route
            path="/calendar-data/:itemId"
            element={<AdminCalendarDataItemInfo />}
          />
          <Route path="/create-token" element={<AdminToken />} />
          <Route path="/calendar-data" element={<AdminCalendarData />} />
          <Route
            path="/schadstoffmobil/standorte"
            element={<AdminSchadstoffmobilStandorte />}
          />
          {(appConfig.logo === "bk" || appConfig.logo === "awl") && (
            <Route path="/city-parts" element={<AdminCityParts />} />
          )}
          {["sensis"].includes(appConfig.logo) && (
            <Route path="/articles" element={<AdminArticles />} />
          )}
          {["sensis"].includes(appConfig.logo) && (
            <Route
              path="/articles/create"
              element={<AdminCreateUpdateArticle />}
            />
          )}
          {["sensis"].includes(appConfig.logo) && (
            <Route
              path="/articles/update/:id"
              element={<AdminCreateUpdateArticle />}
            />
          )}
          <Route path="/blocked-streets" element={<BlockedStreet />} />
          {["ebwo", "lra", "calw"].includes(appConfig.logo) ? (
            <Route path="/white-list-streets" element={<WhiteListStreets />} />
          ) : null}
          {["ebwo", "lra", "calw"].includes(appConfig.logo) ? (
            <Route path="/grey-list-streets" element={<GreyListStreets />} />
          ) : null}
          <Route path="/portal-settings" element={<AdminPortalSettings />} />
          <Route path="/mail-tracking" element={<AdminMailTracking />} />
          {["calw"].includes(appConfig.logo) ? (
            <Route path="/reclamation" element={<AdminReclamation />} />
          ) : null}
          {["calw"].includes(appConfig.logo) ? (
            <Route
              path="/reclamation/:itemId"
              element={<AdminReclamationById />}
            />
          ) : null}
          {["calw"].includes(appConfig.logo) ? (
            <Route
              path="/behaeltergemeinschaft"
              element={<AdminBehaeltergemeinschaft />}
            />
          ) : null}
          {["calw"].includes(appConfig.logo) ? (
            <Route
              path="/behaeltergemeinschaft/:itemId"
              element={<AdminBehaeltergemeinschaftInfoById />}
            />
          ) : null}
          {["calw"].includes(appConfig.logo) ? (
            <Route
              path="/eigenkompostierer"
              element={<AdminEigenkompostierer />}
            />
          ) : null}
          {["calw"].includes(appConfig.logo) ? (
            <Route
              path="/eigenkompostierer/:itemId"
              element={<AdminEigenkompostiererInfoById />}
            />
          ) : null}
          {["lra", "calw", "viersen"].includes(appConfig.logo) ? (
            <Route path="/sepa" element={<AdminSepa />} />
          ) : null}
          {["lra", "calw", "viersen"].includes(appConfig.logo) ? (
            <Route path="/sepa/:itemId" element={<AdminSepaById />} />
          ) : null}
          {["lra"].includes(appConfig.logo) ? (
            <Route
              path="/eigentuemerwechsel"
              element={<AdminEigentuemerwechsel />}
            />
          ) : null}
          {["lra"].includes(appConfig.logo) ? (
            <Route
              path="/eigentuemerwechsel/:itemId"
              element={<AdminEigentuemerwechselById />}
            />
          ) : null}
          {["lra", "calw"].includes(appConfig.logo) ? (
            <Route
              path="/behaelterverwaltung"
              element={<AdminBehaelterverwaltung />}
            />
          ) : null}
          {["lra", "calw"].includes(appConfig.logo) ? (
            <Route
              path="/behaelterverwaltung/:itemId"
              element={<AdminBehaelterverwaltungById />}
            />
          ) : null}
          {["lra"].includes(appConfig.logo) ? (
            <Route path="/zusatzleisung" element={<AdminZusatzleisung />} />
          ) : null}
          {["lra"].includes(appConfig.logo) ? (
            <Route
              path="/zusatzleisung/:id"
              element={<AdminZusatzleisungById />}
            />
          ) : null}
          {["lra", "calw"].includes(appConfig.logo) ? (
            <Route path="/users" element={<AdminUsers />} />
          ) : null}
          {["lra", "calw"].includes(appConfig.logo) ? (
            <Route path="/users/new-user" element={<AdminUserById />} />
          ) : null}
          {["lra", "calw"].includes(appConfig.logo) ? (
            <Route path="/users/:id" element={<AdminUserById />} />
          ) : null}
        </Routes>
      </div>
    </div>
  );
};

export default AdminMain;
