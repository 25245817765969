import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { SortDescriptor } from "@progress/kendo-data-query";
import {
  AdminContentWrapper,
  AdminHeader,
  Button,
  Loader,
  TableGrid,
  TableLastItem,
} from "../../components";
import { GridColumn } from "@progress/kendo-react-grid";
import appConfig from "../../util/appConfig";
import { useNavigate } from "react-router-dom";
import {
  clearAdminUserData,
  getAdminUsers,
} from "../../store/slices/adminUser";
import { format } from "date-fns";
import { de } from "date-fns/locale";

const AdminUsers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { data, itemsCount } = useAppSelector((state) => state.adminUser.admin);
  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminUsers({
        signal,
        direction: sort[0]?.dir,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value]);

  useEffect(() => {
    return () => {
      dispatch(clearAdminUserData());
    };
  }, []);

  const CustomTableItem = () => {
    return (
      <Button
        text={"Neuen Benutzer erstellen"}
        width="200"
        textColor={appConfig.mainColor}
        isOutlined
        outlineColor={appConfig.mainColor}
        onClick={() => {
          navigate("new-user");
        }}
      />
    );
  };

  return (
    <div>
      <AdminHeader pageTitle={"Benutzer"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={data.map((el) => {
            return {
              ...el,
              name: `${el?.firstName ?? ""} ${el?.lastName ?? ""}`,
              createdAt: el?.createdAt
                ? format(new Date(el?.createdAt), "dd.MM.yyyy, HH:mm", {
                    locale: de,
                  })
                : "",
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          items={[
            { field: "createdAt", title: "Datum" },
            { field: "name", title: "Name" },
            { field: "email", title: "EMail" },
            { field: "role", title: "Role" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={itemsCount}
          withDatePicker
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          customItem={CustomTableItem}
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminUsers;
