import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
  History,
} from "../../components";
import style from "./style.module.scss";
import {
  clearAdminEigenkompostiererItem,
  getAdminEigenkompostiererItemById,
  updateAdminEigenkompostiererItemById,
} from "../../store/slices/eigenkompostierer";
import appConfig from "../../util/appConfig";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import transformHexToRgba from "../../util/transformHexToRgba";

export const statuses: { [key: string]: string } = {
  accepted: "Fertig",
  declined: "Storniert",
  inWork: "In Arbeit",
};

const AdminEigenkompostiererInfoById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { eigenkompostiererItem, isEigenkompostiererItemDataLoading } =
    useAppSelector((state) => state.eigenkompostierer.admin);

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminEigenkompostiererItemById(itemId));
    }
    return () => {
      dispatch(clearAdminEigenkompostiererItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  const items = [
    {
      title: "",
      items: [
        {
          rowTitle: "Status",
          rowInfo: `${
            eigenkompostiererItem?.status
              ? statuses[eigenkompostiererItem.status]
              : ""
          }`,
        },
        {
          rowTitle: "Datum (Meldung)",
          rowInfo: `${
            eigenkompostiererItem?.createdAt
              ? format(
                  new Date(eigenkompostiererItem.createdAt),
                  "dd.MM.yyyy, HH:mm",
                  {
                    locale: de,
                  }
                )
              : ""
          }`,
        },
      ],
    },
    {
      title: "Kontaktdaten",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${eigenkompostiererItem?.userSurname} ${eigenkompostiererItem?.userName}`,
        },
        {
          rowTitle: "Strasse",
          rowInfo: `${eigenkompostiererItem?.street} ${
            eigenkompostiererItem?.homeNumber
          }${
            eigenkompostiererItem?.hausNrZusatz
              ? ` ${eigenkompostiererItem?.hausNrZusatz}`
              : ""
          }`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${eigenkompostiererItem?.postCode} ${
            eigenkompostiererItem?.placePart
              ? eigenkompostiererItem?.placePart
              : ""
          }`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: `${
            eigenkompostiererItem?.phoneNumber
              ? eigenkompostiererItem?.phoneNumber
              : ""
          }`,
        },
        {
          rowTitle: "E-Mail",
          rowInfo: `${
            eigenkompostiererItem?.email ? eigenkompostiererItem?.email : ""
          }`,
        },
        {
          rowTitle: "Kassenzeichen",
          rowInfo: `${eigenkompostiererItem?.bookingCode}`,
        },
        {
          rowTitle: "Nachricht",
          rowInfo: `${eigenkompostiererItem?.message}`,
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        {isEigenkompostiererItemDataLoading ? null : !eigenkompostiererItem ? null : (
          <div>
            <AdminDetailsPageItemName text="Detailinformationen" />
            <div>
              {items.map((el, idx) => {
                return (
                  <AdminDetailInfoItem
                    items={el.items}
                    title={el.title}
                    key={idx}
                  />
                );
              })}
              {eigenkompostiererItem?.history?.length ? (
                <History history={eigenkompostiererItem?.history} />
              ) : null}
            </div>
            <div
              style={{
                // textAlign: "end",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                text="Offen"
                width="200"
                // backgroundColor={appConfig.secondaryColor}
                isOutlined
                outlineColor={
                  eigenkompostiererItem?.status === "inWork"
                    ? transformHexToRgba(
                        appConfig.secondaryColor || appConfig.mainColor,
                        "0.4"
                      )
                    : appConfig.secondaryColor || appConfig.mainColor
                }
                textColor={
                  eigenkompostiererItem?.status === "inWork"
                    ? transformHexToRgba(
                        appConfig.secondaryColor || appConfig.mainColor,
                        "0.4"
                      )
                    : appConfig.secondaryColor || appConfig.mainColor
                }
                disabled={eigenkompostiererItem?.status === "inWork"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminEigenkompostiererItemById({
                        id: itemId,
                        status: "inWork",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Akzeptieren"
                width="200"
                backgroundColor={
                  eigenkompostiererItem?.status === "accepted"
                    ? transformHexToRgba(appConfig.mainColor, "0.4")
                    : appConfig.mainColor
                }
                disabled={eigenkompostiererItem?.status === "accepted"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminEigenkompostiererItemById({
                        id: itemId,
                        status: "accepted",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={
                  eigenkompostiererItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                textColor={
                  eigenkompostiererItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                disabled={eigenkompostiererItem?.status === "declined"}
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminEigenkompostiererItemById({
                        id: itemId,
                        status: "declined",
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminEigenkompostiererInfoById;
