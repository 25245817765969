import React, { FC } from "react";
import style from "./style.module.scss";
import nextWhiteArrow from "../../assets/images/arrowNextWhite.svg";
import downArrow from "../../assets/images/downArrow.svg";
import appConfig from "../../util/appConfig";

interface IButton {
  backgroundColor?: string;
  text: any;
  textColor?: string;
  hasLeftArrow?: boolean;
  hasRightArrow?: boolean;
  hasDownArrow?: boolean;
  boxShadow?: string;
  isOutlined?: boolean;
  outlineColor?: string;
  width: string;
  onClick?: () => void;
  type?: "button" | "submit";
  disabled?: boolean;
  isWidthInPercentage?: boolean;
  btnStyle?: React.CSSProperties;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  customImg?: string;
  hintText?: string;
}

export const Button: FC<IButton> = ({
  backgroundColor = "#fff",
  text,
  textColor = "#fff",
  boxShadow,
  hasLeftArrow = false,
  hasRightArrow = false,
  hasDownArrow = false,
  isOutlined,
  outlineColor,
  width,
  onClick,
  type = "button",
  disabled = false,
  isWidthInPercentage = false,
  btnStyle,
  height,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  customImg,
  hintText,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={style.btn_wrapper}
      style={{
        ...btnStyle,
        color: textColor,
        backgroundColor: backgroundColor,
        boxShadow: boxShadow && boxShadow,
        border: isOutlined ? `1px solid ${outlineColor}` : "",
        width: !isWidthInPercentage ? `${width}px` : "100%",
        height: height ? `${height}px` : "",
        marginTop: marginTop ? `${marginTop}px` : "",
        marginBottom: marginBottom ? `${marginBottom}px` : "",
        marginLeft: marginLeft ? `${marginLeft}px` : "",
        marginRight: marginRight ? `${marginRight}px` : "",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={handleClick}
      type={type}
      disabled={disabled}
      title={hintText}
    >
      {hasLeftArrow && (
        <div className={style.back}>
          <BackArrow
            currentColor={
              appConfig.secondaryColor
                ? appConfig.secondaryColor
                : appConfig.mainColor
            }
          />
        </div>
      )}
      {customImg && <img className={style.back} src={customImg} alt="back" />}
      {text}
      {hasRightArrow && (
        <img className={style.next} src={nextWhiteArrow} alt="back" />
      )}
      {hasDownArrow && (
        <img className={style.next} src={downArrow} alt="back" />
      )}
    </button>
  );
};

const BackArrow = ({ currentColor = "#0087CE" }: { currentColor: string }) => {
  return (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.605753 4.74365L4.24203 1.10734C4.37265 0.954808 4.60221 0.937027 4.75474 1.06768C4.90727 1.1983 4.92505 1.42786 4.7944 1.58039C4.78221 1.59461 4.76896 1.60789 4.75474 1.62005L1.74026 4.63818H16.1364C16.3372 4.63818 16.5 4.80099 16.5 5.00183C16.5 5.20267 16.3372 5.36545 16.1364 5.36545H1.74026L4.75474 8.37992C4.90727 8.51055 4.92505 8.74011 4.7944 8.89263C4.66374 9.04516 4.43422 9.06294 4.28169 8.93229C4.26747 8.9201 4.25419 8.90685 4.24203 8.89263L0.605721 5.25632C0.464756 5.11455 0.464756 4.88549 0.605753 4.74365Z"
        fill={currentColor}
        strokeWidth="0.4"
      />
    </svg>
  );
};
