import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAdminNotificationItemsCount,
  setErrorMessage,
  setIsLoading,
  setIsToasterError,
} from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";
import { IBehaeltervervaltungResponseBody } from "./behaelterverwaltung";
import appConfig from "../../util/appConfig";

export interface IZusatzleistungenResponseBody
  extends IBehaeltervervaltungResponseBody {}

export interface IZusatzleistung {
  id: string;
  gemeindenr: number;
  kassenzeichen: number;
  objektid: number;
  phoneNumber: string;
  data: { behid: number; service: string[] }[];
  status: string;
  username: string;
  usersurname: string;
  deleted: boolean;
  plz: string;
  strassenr: number;
  strassebez: string;
  gemeindebez: string;
  ortsteilbez: string;
  hausnr: number;
  hausnrzusatz?: string | null;
  adressart: string;
  createdAt: string;
}

export interface IZusatzleistungenState {
  client: {
    zusatzleistungenData: IZusatzleistungenResponseBody[];
  };
  admin: {
    itemsCount: number;
    zusatzleistungenData: IZusatzleistung[];
    zusatzleistungenItem: IZusatzleistung | null;
    isZusatzleistungenItemDataLoading: boolean;
  };
}

const initialState: IZusatzleistungenState = {
  client: {
    zusatzleistungenData: [],
  },
  admin: {
    isZusatzleistungenItemDataLoading: false,
    itemsCount: 0,
    zusatzleistungenData: [],
    zusatzleistungenItem: null,
  },
};

export const getZusatzleistungen = createAsyncThunk(
  "zusatzleistungen/client/getZusatzleistungen",
  async (
    {
      gemeindeNr,
      kassenzeichen,
      objId,
    }: {
      gemeindeNr: string | number;
      kassenzeichen: string | number;
      objId: string | number;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.zusatzleistung.get.getZusatzleistungen({
        gemeindeNr,
        kassenzeichen,
        objId,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const createZusatzleistung = createAsyncThunk(
  "zusatzleistungen/client/createZusatzleistung",
  async (
    {
      gemeindeNr,
      kassenzeichen,
      objId,
      data: itemData,
      phoneNumber,
    }: {
      gemeindeNr: string | number;
      kassenzeichen: string | number;
      objId: string | number;
      phoneNumber: string;
      data: { behid: number; service: string[] }[];
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      await API.zusatzleistung.post.createZusatzleistung({
        gemeindeNr,
        kassenzeichen,
        objId,
        data: itemData,
        phoneNumber,
      });
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getAdminZusatzleistungen = createAsyncThunk(
  "zusatzleistungen/admin/getAdminZusatzleistungen",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
      endDate,
      startDate,
      status,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      startDate?: Date | null | string;
      endDate?: Date | null | string;
      status?: string[];
      signal: AbortSignal;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.zusatzleistung.get.getAdminZusatzleistungen({
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        signal,
        endDate,
        startDate,
        status,
      });
      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);
export const getAdminZusatzleistungenById = createAsyncThunk(
  "zusatzleistungen/admin/getAdminZusatzleistungenById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.zusatzleistung.get.getAdminZusatzleistungenById(
        id
      );
      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const updateStatusById = createAsyncThunk(
  "zusatzleistungen/admin/updateStatusById",
  async ({ id, status }: { id: string; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.zusatzleistung.put.updateStatusById({
        id,
        status,
      });
      if (["lra"].includes(appConfig.logo)) {
        dispatch(getAdminNotificationItemsCount());
      }
      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

const zusatzleistungSlice = createSlice({
  name: "zusatzleistungen",
  initialState,
  reducers: {
    clearZusatzleisungen: (state) => {
      state.client.zusatzleistungenData = [];
    },
    clearAdminZusatzleisungenData: (state) => {
      state.admin.itemsCount = 0;
      state.admin.isZusatzleistungenItemDataLoading = false;
      state.admin.zusatzleistungenData = [];
    },
    clearAdminZusatzleisungenById: (state) => {
      state.admin.isZusatzleistungenItemDataLoading = false;
      state.admin.zusatzleistungenItem = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getZusatzleistungen.pending, (state) => {});
    builder.addCase(getZusatzleistungen.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.zusatzleistungenData = [...action.payload];
      }
    });
    builder.addCase(getZusatzleistungen.rejected, (state) => {});
    builder.addCase(createZusatzleistung.pending, (state) => {});
    builder.addCase(createZusatzleistung.fulfilled, (state, action) => {});
    builder.addCase(createZusatzleistung.rejected, (state) => {});
    //getAdminZusatzleistungen
    builder.addCase(getAdminZusatzleistungen.pending, (state) => {
      state.admin.isZusatzleistungenItemDataLoading = true;
    });
    builder.addCase(getAdminZusatzleistungen.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.zusatzleistungenData = [...action.payload.data];
        state.admin.itemsCount = action.payload.totalCount;
      }
      state.admin.isZusatzleistungenItemDataLoading = false;
    });
    builder.addCase(getAdminZusatzleistungen.rejected, (state) => {
      state.admin.isZusatzleistungenItemDataLoading = false;
    });
    builder.addCase(getAdminZusatzleistungenById.pending, (state) => {
      state.admin.isZusatzleistungenItemDataLoading = true;
    });
    builder.addCase(getAdminZusatzleistungenById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.zusatzleistungenItem = action.payload;
      }
      state.admin.isZusatzleistungenItemDataLoading = false;
    });
    builder.addCase(getAdminZusatzleistungenById.rejected, (state) => {
      state.admin.isZusatzleistungenItemDataLoading = false;
    });
    builder.addCase(updateStatusById.pending, (state) => {
      state.admin.isZusatzleistungenItemDataLoading = true;
    });
    builder.addCase(updateStatusById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.zusatzleistungenItem = action.payload;
      }
      state.admin.isZusatzleistungenItemDataLoading = false;
    });
    builder.addCase(updateStatusById.rejected, (state) => {
      state.admin.isZusatzleistungenItemDataLoading = false;
    });
  },
});

export const {
  clearZusatzleisungen,
  clearAdminZusatzleisungenById,
  clearAdminZusatzleisungenData,
} = zusatzleistungSlice.actions;

export const zusatzleistungReducer = zusatzleistungSlice.reducer;
