import React, { FC } from "react";
import style from "./style.module.scss";
import { format } from "date-fns";
import { statuses } from "../../pages/AdminSperrmuelanmeldungen";
// import { statuses } from "../../../../AdminSperrmuelanmeldungen";

interface IHistory {
  history: {
    id: string;
    createdAt: string;
    status: string;
    email: string;
    firstName?: string;
    lastName?: string;
  }[];
}

export const History: FC<IHistory> = ({ history }) => {
  return (
    <div>
      <div className={style.title}>Weitere Informationen</div>
      <div className={style.tableInfoWrapper}>
        <div
          className={`${style.rowWrapper}`}
          style={{
            borderBottom: "none",
          }}
        >
          <div className={style.rowTitle}>Historie</div>
          <div className={style.rowInfo}>
            {history.map((el, idx) => {
              return (
                <div style={{}} key={idx}>
                  <div>
                    Datum: {format(new Date(el.createdAt), "dd.MM.yyyy HH:mm")}
                  </div>
                  <div>Benutzer: {el.email}</div>
                  {el?.firstName && el?.lastName ? (
                    <div>
                      Name: {el.lastName} {el.firstName}
                    </div>
                  ) : null}
                  <div>Status: {statuses[el.status]}</div>
                  {history.length === idx + 1 ? null : (
                    <div
                      style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "black",
                        margin: "10px 0",
                      }}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
