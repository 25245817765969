import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setErrorMessage,
  setIsToasterError,
  setIsToasterSuccess,
  setSuccessMessage,
} from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";

export interface IAdminUserBody {
  email: string;
  firstName: string;
  lastName: string;
  role?: string;
  password?: string;
}

export interface IAdminUserResponseBody extends IAdminUserBody {
  id: string;
  createdAt: string;
}

interface IAdminUser {
  admin: {
    data: IAdminUserResponseBody[];
    itemsCount: number;
    isAdminUserDataLoading: boolean;
    adminUserItem: IAdminUserResponseBody | null;
  };
}

const initialState: IAdminUser = {
  admin: {
    data: [],
    itemsCount: 0,
    isAdminUserDataLoading: false,
    adminUserItem: null,
  },
};

export const getAdminUsers = createAsyncThunk(
  "adminUser/admin/getAdminUsers",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      signal: AbortSignal;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data = API.adminUsers.get.getAdminUsers({
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        signal,
      });

      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getAdminUserById = createAsyncThunk(
  "adminUser/admin/getAdminUserById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.adminUsers.get.getAdminUserById(id);

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const createAdminUser = createAsyncThunk(
  "adminUser/admin/createAdminUser",
  async (data: IAdminUserBody, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      await API.adminUsers.post.createAdminUser(data);

      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Erfogreich erstellt!"));
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const updateAdminUserById = createAsyncThunk(
  "adminUser/admin/updateAdminUserById",
  async ({ id, data }: { id: string; data: IAdminUserBody }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      await API.adminUsers.put.updateAdminUserById({ id, data });

      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Erfogreich erneurt!"));
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const deleteAdminUser = createAsyncThunk(
  "adminUser/admin/deleteAdminUser",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      await API.adminUsers.delete.deleteAdminUser(id);

      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Erfogreich gelöscht!"));
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

const adminUserSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    clearAdminUserData: (state) => {
      state.admin.data = [];
      state.admin.itemsCount = 0;
    },
    clearAdminUserItem: (state) => {
      state.admin.isAdminUserDataLoading = false;
      state.admin.adminUserItem = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminUsers.pending, (state) => {
      state.admin.isAdminUserDataLoading = true;
    });
    builder.addCase(getAdminUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.itemsCount = action.payload.totalCount;
        state.admin.data = [...action.payload.data];
      }
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(getAdminUsers.rejected, (state) => {
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(getAdminUserById.pending, (state) => {
      state.admin.isAdminUserDataLoading = true;
    });
    builder.addCase(getAdminUserById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.adminUserItem = action.payload;
      }
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(getAdminUserById.rejected, (state) => {
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(createAdminUser.pending, (state) => {
      state.admin.isAdminUserDataLoading = true;
    });
    builder.addCase(createAdminUser.fulfilled, (state, action) => {
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(createAdminUser.rejected, (state) => {
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(updateAdminUserById.pending, (state) => {
      state.admin.isAdminUserDataLoading = true;
    });
    builder.addCase(updateAdminUserById.fulfilled, (state, action) => {
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(updateAdminUserById.rejected, (state) => {
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(deleteAdminUser.pending, (state) => {
      state.admin.isAdminUserDataLoading = true;
    });
    builder.addCase(deleteAdminUser.fulfilled, (state, action) => {
      state.admin.isAdminUserDataLoading = false;
    });
    builder.addCase(deleteAdminUser.rejected, (state) => {
      state.admin.isAdminUserDataLoading = false;
    });
  },
});

export const { clearAdminUserData, clearAdminUserItem } =
  adminUserSlice.actions;

export const adminUserReducer = adminUserSlice.reducer;
