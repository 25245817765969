import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { setIsBurgerMenu } from "../../store/slices/app";
import appConfig from "../../util/appConfig";
import { Button } from "../Button";
import style from "./style.module.scss";
import { HeaderSelect, SmallLoader } from "..";

const createLinkHandler = (link: string) => {
  const a = document.createElement("a");
  a.href = link;
  a.click();
};

export const BurgerMenu = () => {
  const dispatch = useAppDispatch();
  const isBurgerMenuOpen = useAppSelector(
    (state) => state.app.client.isBurgerMenuOpen
  );
  const {
    objectIdUser: { nachname, vorname },
    isUserDataLoading,
  } = useAppSelector((state) => state.user);

  const linkHandler = () => {
    dispatch(setIsBurgerMenu(false));
  };

  return isBurgerMenuOpen ? (
    <div
      className={`${style.wrapper}`}
      style={{
        top: `${window.pageYOffset}px`,
      }}
    >
      <div className={style.helperWrapper}>
        <nav className={style.linkWrapper}>
          <div className={style.linkWithMargin}>
            <Link to="/" onClick={linkHandler}>
              PORTAL
            </Link>
          </div>
          {!["lra"].includes(appConfig.logo) ? (
            <div className={style.linkWithMargin}>
              <a href="/#faq" onClick={linkHandler}>
                FAQS
              </a>
            </div>
          ) : null}
          {appConfig.logo === "sensis" ? (
            <div className={style.link}>
              <Link to="/actual" onClick={linkHandler}>
                AKTUELLES
              </Link>
            </div>
          ) : null}
          {["lra", "calw"].includes(appConfig.logo) ? (
            isUserDataLoading ? (
              <SmallLoader loaderDotSize="7" />
            ) : !nachname || !vorname ? (
              <Link
                to={"/login"}
                className={`${style.anmelden}`}
                onClick={linkHandler}
              >
                Anmelden
              </Link>
            ) : (
              <HeaderSelect />
            )
          ) : null}
        </nav>
        {appConfig.logo === "sensis" ? (
          <div className={style.btnWrapper}>
            <Button
              text="Anmelden"
              width="100"
              backgroundColor={appConfig.mainColor}
              isWidthInPercentage={true}
              onClick={() => createLinkHandler(appConfig.nrwLoginURl)}
            />
            <Button
              text="Registrieren"
              width="100"
              backgroundColor={appConfig.colorForBtnBackground}
              isWidthInPercentage={true}
              isOutlined={true}
              outlineColor={appConfig.mainColor}
              textColor={appConfig.mainColor}
              onClick={() => createLinkHandler(appConfig.nrwSignupUrl)}
            />
          </div>
        ) : null}
        {appConfig.logo === "lra" ? (
          <Link
            to={"/login"}
            className={`${style.portal_link} ${style.main_link} ${style.anmelden}`}
          >
            Anmelden
          </Link>
        ) : null}
      </div>
    </div>
  ) : null;
};
