import React, { FC } from "react";
import appConfig from "../../util/appConfig";
import style from "./style.module.scss";

interface IAdminCheckbox {
  checked: boolean;
  changeHandler: () => void;
}

export const AdminCheckbox: FC<IAdminCheckbox> = ({
  checked,
  changeHandler,
}) => {
  return (
    <div
      className={`${style.wrapper} ${checked && style.activeWrapper}`}
      style={{
        borderColor: checked ? appConfig.mainColor : "",
      }}
      onClick={changeHandler}
    >
      {checked ? (
        <div
          className={style.checked}
          style={{
            background: appConfig.mainColor,
            borderColor: appConfig.mainColor
          }}
        ></div>
      ) : null}
    </div>
  );
};
