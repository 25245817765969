import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";

export interface IEigentuemerwechselBody {
  objectId: string;
  hausnr: string;
  hausnrzusatz?: string;
  strasse: string;
  ort: string;
  // gemeinde: string;
  previousOwnerAnrede: string;
  previousOwnerSurname: string;
  previousOwnerName: string;
  previousOwnerStrasse: string;
  previousOwnerHausnr: string;
  previousOwnerHausnrzusatz?: string;
  previousOwnerPlz: string;
  previousOwnerOrt: string;
  previousOwnerPhoneNumber: string;
  previousOwnerEmail: string;
  newOwnerAnrede: string;
  newOwnerSurname: string;
  newOwnerName: string;
  newOwnerStrasse: string;
  newOwnerHausnr: string;
  newOwnerHausnrzusatz?: string;
  newOwnerPlz: string;
  newOwnerOrt: string;
  newOwnerPhoneNumber: string;
  newOwnerEmail: string;
  newOwnerSince: string;
  newOwnerQuantityOfPeople: string;
  transferDate: string;
}

export interface IEigentuemerwechselResponseBody
  extends IEigentuemerwechselBody {
  id: string;
  createdAt: string;
}

interface ISepa {
  admin: {
    data: IEigentuemerwechselResponseBody[];
    itemsCount: number;
    isDataLoading: boolean;
    eigentuemerwechselItem: IEigentuemerwechselResponseBody | null;
  };
}

const initialState: ISepa = {
  admin: {
    data: [],
    itemsCount: 0,
    isDataLoading: false,
    eigentuemerwechselItem: null,
  },
};

export const createEigentuemerwechsel = createAsyncThunk(
  "eigentuemerwechsel/client/createEigentuemerwechsel",
  async (data: IEigentuemerwechselBody, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      await API.eigentuemerwechsel.post.create(data);
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getAdminEigentuemerwechsel = createAsyncThunk(
  "eigentuemerwechsel/admin/getAdminEigentuemerwechsel",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
      endDate,
      startDate,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      startDate?: Date | null | string;
      endDate?: Date | null | string;
      signal: AbortSignal;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data = API.eigentuemerwechsel.get.getAdminEigentuemerwechselData({
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        signal,
        endDate,
        startDate,
      });

      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getAdminEigentuemerwechselItemById = createAsyncThunk(
  "eigentuemerwechsel/admin/getAdminEigentuemerwechselItemById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        await API.eigentuemerwechsel.get.getAdminEigentuemerwechselItemById(id);

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

const eigentuemerwechselSlice = createSlice({
  name: "eigentuemerwechsel",
  initialState,
  reducers: {
    clearAdminEigentuemerwechselData: (state) => {
      state.admin.data = [];
      state.admin.itemsCount = 0;
    },
    clearAdminEigentuemerwechselItem: (state) => {
      state.admin.isDataLoading = false;
      state.admin.eigentuemerwechselItem = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminEigentuemerwechsel.pending, (state) => {});
    builder.addCase(getAdminEigentuemerwechsel.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.itemsCount = action.payload.totalCount;
        state.admin.data = [...action.payload.data];
      }
    });
    builder.addCase(getAdminEigentuemerwechsel.rejected, (state) => {});
    builder.addCase(getAdminEigentuemerwechselItemById.pending, (state) => {
      state.admin.isDataLoading = true;
    });
    builder.addCase(
      getAdminEigentuemerwechselItemById.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.eigentuemerwechselItem = action.payload;
        }
        state.admin.isDataLoading = false;
      }
    );
    builder.addCase(getAdminEigentuemerwechselItemById.rejected, (state) => {
      state.admin.isDataLoading = false;
    });
  },
});

export const {
  clearAdminEigentuemerwechselData,
  clearAdminEigentuemerwechselItem,
} = eigentuemerwechselSlice.actions;

export const eigentuemerwechselReducer = eigentuemerwechselSlice.reducer;
