import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { SortDescriptor } from "@progress/kendo-data-query";
import {
  AdminCheckbox,
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
  TableLastItem,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  changeReclamationItemsStatuses,
  getAdminReclamation,
} from "../../store/slices/reclamation";
import { format } from "date-fns";
import { statuses } from "../AdminSperrmuelanmeldungen";
import appConfig from "../../util/appConfig";
import exploreArrow from "../../assets/images/exploreArrow.svg";
import {
  GridCellProps,
  GridColumn,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import { de } from "date-fns/locale";
import { getAdminNotificationItemsCount } from "../../store/slices/app";

const AdminReclamation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { itemsCount, reclamationData } = useAppSelector(
    (state) => state.reclamation.admin
  );

  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);
  const [abfallartType, setAbfallartType] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end || end === null) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };
  const changeAbfallartValue = (st: string[]) => {
    setAbfallartType([...st]);
  };

  const setAcceptedStatus = async () => {
    if (["calw"].includes(appConfig.logo)) {
      await dispatch(
        changeReclamationItemsStatuses({
          ids: reclamationData.map((el) => el.id),
          status: "accepted",
        })
      );
      await dispatch(getAdminNotificationItemsCount());

      return;
    }
  };

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = reclamationData.map((i) => i.id);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: string) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const FirstItem = (props: GridCellProps) => {
    return (
      <td
        // className="k-command-cell"
        style={
          {
            // backgroundColor: 'white',
          }
        }
      >
        <AdminCheckbox
          checked={selectedItems.includes(props.dataItem.id)}
          changeHandler={() => {
            selectItemHandler(props.dataItem.id);
          }}
        />
      </td>
    );
  };
  const FirstHeaderItem = (props: GridHeaderCellProps) => {
    return (
      <div
        style={{
          margin: "0",
          padding: "0",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          // border: "none",
          // backgroundColor: "rgba(0, 0, 0, 0.02) !important",
        }}
        // className={style.qwe}
      >
        <AdminCheckbox
          checked={allItemsSelected}
          changeHandler={allItemsSelectHandler}
        />
      </div>
    );
  };

  const generateSelectOptions = (type: string) => {
    switch (type) {
      case "calw": {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
          { label: statuses.declined, value: "declined" },
        ];
      }
      default: {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
        ];
      }
    }
  };

  // const LastItem = (props: GridCellProps) => {
  //   return (
  //     <td
  //       className="k-table-td"
  //       style={{
  //         border: "none",
  //       }}
  //       key={Math.floor(Math.random() * 1000000000000)}
  //     >
  //       <div
  //         className={style.detailBtn}
  //         onClick={() => {
  //           navigate(props.dataItem.id);
  //         }}
  //       >
  //         <img src={exploreArrow} alt="info" />
  //       </div>
  //     </td>
  //   );
  // };

  const generateAbfallArtTypes = (type: string) => {
    switch (type) {
      case "calw": {
        return [
          { label: "Restmüll", value: "Restmüll" },
          { label: "Biomüll", value: "Biomüll" },
          { label: "Glas", value: "Glas" },
          {
            label: "Papier, Pappe, Kartonage",
            value: "Papier, Pappe, Kartonage",
          },
        ];
      }
      default: {
        return [];
      }
    }
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminReclamation({
        direction: sort[0]?.dir,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        signal,
        startDate: startDate ? new Date(startDate).toUTCString() : startDate,
        endDate: endDate ? new Date(endDate).toUTCString() : endDate,
        status,
        type: abfallartType,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status, abfallartType]);

  const selectorOptions = [
    {
      placeholder: "Bitte Status wählen",
      isActive: ["calw"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: generateSelectOptions(appConfig.logo).filter(
        (el) => !status.includes(el.value)
      ),
      value: status.map((el) => {
        return {
          value: el,
          label: statuses[el],
        };
      }),
      onChange: changeStatuses,
    },
    {
      placeholder: "Bitte Abfallart wählen",
      isActive: ["calw"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: generateAbfallArtTypes(appConfig.logo),
      value: abfallartType.map((el) => {
        return { value: el, label: el };
      }),
      onChange: changeAbfallartValue,
    },
  ];

  return (
    <div>
      <AdminHeader pageTitle={"Reklamation"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={reclamationData.map((el) => {
            return {
              ...el,
              street: `${el.street} ${el.homeNumber}${
                el?.hausNrZusatz ? ` ${el?.hausNrZusatz}` : ""
              }`,
              name: `${el.userSurname}, ${el.userName} `,
              createdAt: el.createdAt
                ? format(new Date(el.createdAt), "dd.MM.yyyy, HH:mm")
                : "",
              time: el.createdAt ? format(new Date(el.createdAt), "HH:mm") : "",
              // date: el.date ? format(new Date(el.date), "dd.MM.yyyy") : "",
              date: format(new Date(el.date), "dd.MM.yyyy"),
              status: el.status ? statuses[el.status] : "",
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          items={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "name", title: "Nachname, Vorname" },
            { field: "street", title: "Straße" },
            { field: "postCode", title: "PLZ" },
            { field: "place", title: "Ort" },
            { field: "type", title: "Abfallart" },
            { field: "reclamationReason", title: "Reklamationsgrund" },
            { field: "date", title: "Leistungsdatum" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={itemsCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          onExcelExportClickHandler={setAcceptedStatus}
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          selectorOptions={selectorOptions}
          withExcelExport={["calw"].includes(appConfig.logo)}
          excelExportData={reclamationData
            .filter((el) => selectedItems.includes(el.id))
            .map((el) => {
              return {
                Status: el?.status ? statuses[el?.status] : "",
                "Datum (Meldung)": el?.createdAt
                  ? format(new Date(el?.createdAt), "dd.MM.yyyy, HH:mm", {
                      locale: de,
                    })
                  : "",
                Name: `${el?.userSurname}, ${el?.userName}`,
                Straße: `${el?.street} ${el?.homeNumber} ${
                  el?.hausNrZusatz ? " " + el?.hausNrZusatz : ""
                }`,
                Ort: `${el?.postCode ? el.postCode : ""} ${el?.place}`,
                Telefonnummer: `${
                  el?.phoneNumber ? "+ " + el?.phoneNumber : ""
                }`,
                "E-Mail": `${el?.email}`,
                Abfallart: `${el?.type ? el?.type : ""}`,
                Reklamationsgrund: `${el?.reclamationReason}`,
                Leistungsdatum: `${
                  el?.date
                    ? format(new Date(el?.date), "dd.MM.yyyy", {
                        locale: de,
                      })
                    : ""
                }`,
              };
            })}
          excelExportItem={[
            {
              field: "Status",
              title: "Status",
            },
            {
              field: "Datum (Meldung)",
              title: "Datum (Meldung)",
            },
            {
              field: "Name",
              title: "Name",
            },
            {
              field: "Straße",
              title: "Straße",
            },
            {
              field: "Ort",
              title: "Ort",
            },
            {
              field: "Telefonnummer",
              title: "Telefonnummer",
            },
            {
              field: "E-Mail",
              title: "E-Mail",
            },
            {
              field: "Abfallart",
              title: "Abfallart",
            },
            {
              field: "Reklamationsgrund",
              title: "Reklamationsgrund",
            },
            {
              field: "Leistungsdatum",
              title: "Leistungsdatum",
            },
          ]}
          firstItem={
            ["calw"].includes(appConfig.logo) ? (
              <GridColumn
                cell={FirstItem}
                width="40px"
                headerCell={FirstHeaderItem}
              />
            ) : null
          }
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminReclamation;
