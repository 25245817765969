import React, { FC } from "react";
import style from "./style.module.scss";
import arrowBackBlack from "../../assets/images/arrowBackBlack.svg";
import { Title } from "..";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";

interface ITitleComponent {
  title: string;
  marginBottom?: string;
  titleWidthContainer?: string;
  withoutBackButton?: boolean;
}

export const TitleComponent: FC<ITitleComponent> = ({
  title,
  marginBottom,
  titleWidthContainer,
  withoutBackButton = false,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useAppSelector((state) => state.app.client);

  const backBtnHandler = () => {
    navigate(-1);
  };

  return (
    <div
      className={style.wrapper}
      style={{
        marginBottom: marginBottom ? `${marginBottom}px` : "",
      }}
    >
      <div
        style={{
          width: titleWidthContainer ? `${titleWidthContainer}px` : "",
          margin: titleWidthContainer ? "0 auto" : "",
          textTransform: "uppercase"
        }}
      >
        <Title
          title={title}
          fontSize="24"
          letterSpacing="1px"
          mobileFontSize="20"
          hasHelperPadding={true}
        />
      </div>
      {withoutBackButton ? null : (
        <div className={style.back} onClick={backBtnHandler}>
          <img src={arrowBackBlack} alt="back" />
          {!isMobile && <span>Zurück</span>}
        </div>
      )}
    </div>
  );
};
