import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAdminNotificationItemsCount,
  setErrorMessage,
  setIsLoading,
  setIsToasterError,
} from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";

export interface ISepaBody {
  username: string;
  userSurname?: string;
  phoneNumber: string;
  contactPhoneNumber: string;
  email: string;
  idNumber: string;
  street: string;
  homeNumber: string;
  homeNumberZusatz?: string;
  postCode: string;
  place: string;
  country?: string;
  iban: string;
  bic: string;
  payerType?: string | undefined;
  ownerName?: string;
  ownerSurname?: string;
  ownerPostCode?: string;
  ownerPlace?: string;
  ownerStreet?: string;
  ownerHomeNumber?: string;
  ownerHomeNumberZusatz?: string;
  gemeindeNr: string;
  isReguliererPresent?: boolean;
  number?: number | null;
  objektId?: number;
}

export interface ISepaUserData {
  abrechnungsartnr: number;
  abstimmkonto: string;
  anrede: string;
  branchekennung: string;
  debitornr: number;
  email?: string | null;
  fremdschluesselkennung: string;
  gpnr?: string | null | number;
  grosskunde_jn?: number | null;
  hausnr: string;
  kontengruppe: string;
  la: number;
  lagehinweis?: null | string;
  land: string;
  mahnverfahrenkennung: string;
  nachname: string;
  namezusatz: null | string;
  ort: string;
  partnergesellschaft: null | string;
  plz: string;
  plzpostfach: null | string;
  postfach: null | string;
  sachbearbeiter1kennung: string;
  sachbearbeiter2kennung: string;
  strasse: string;
  telefax: null | string;
  telefon: null | string;
  titel: null | string;
  va: number;
  vorname: string;
  zahlungsartkennung: string;
  zahlungsbedingungnr: number;
}

export interface ISepaResponseBody extends ISepaBody {
  id: string;
  createdAt: string;
  status: string;
  errorMessage?: string;
  mandatNr?: string;
  isReguliererPresent?: boolean;
}

export interface IBankData {
  zahlungsartkennung: string | null;
  iban: string | null;
  bic: string | null;
  bankname: string | null;
  mandatnr: string | null;
}

interface ISepa {
  admin: {
    data: ISepaResponseBody[];
    itemsCount: number;
    isSepaDataLoading: boolean;
    sepaItem: ISepaResponseBody | null;
  };
  user: {
    bankData: IBankData;
    number?: null | number;
    isReguliererPresent?: boolean;
    userData?: ISepaUserData | null;
  };
}

const initialState: ISepa = {
  admin: {
    data: [],
    itemsCount: 0,
    isSepaDataLoading: false,
    sepaItem: null,
  },
  user: {
    bankData: {
      bankname: null,
      bic: null,
      iban: null,
      mandatnr: null,
      zahlungsartkennung: null,
    },
    isReguliererPresent: false,
    number: null,
    userData: null,
  },
};

export const createSepa = createAsyncThunk(
  "sepa/client/createSepa",
  async (data: ISepaBody & { solution: string }, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      await API.sepa.post.create(data);
    } catch (error: any) {
      // dispatch(setIsToasterError(true));
      // dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const getBankData = createAsyncThunk(
  "sepa/client/getBankData",
  async (
    {
      gemeindeNr,
      objektId,
    }: { gemeindeNr: string; objektId?: string | number },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));

      const data = await API.sepa.get.getBankData({ gemeindeNr, objektId });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getAdminSepa = createAsyncThunk(
  "sepa/admin/getAdminSepa",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
      endDate,
      startDate,
      status,
      payerType,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      startDate?: Date | null | string;
      endDate?: Date | null | string;
      status?: string[];
      signal: AbortSignal;
      payerType: string[];
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data = API.sepa.get.getAdminSepaData({
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        signal,
        endDate,
        startDate,
        status,
        payerType,
      });

      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getAdminSepaItemById = createAsyncThunk(
  "sepa/admin/getAdminSepaItemById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.sepa.get.getAdminSepaItemById(id);

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);
export const updateStatusById = createAsyncThunk(
  "sepa/admin/updateStatusById",
  async ({ id, status }: { id: string; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.sepa.put.updateStatusById({ id, status });

      dispatch(getAdminNotificationItemsCount());
      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const checkRegulierer = createAsyncThunk(
  "sepa/user/checkRegulierer",
  async ({ objektId }: { objektId: string | number }, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.sepa.get.checkRegulierer({ objektId });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

const sepaSlice = createSlice({
  name: "sepa",
  initialState,
  reducers: {
    clearAdminSepaData: (state) => {
      state.admin.data = [];
      state.admin.itemsCount = 0;
    },
    clearAdminSepaItem: (state) => {
      state.admin.isSepaDataLoading = false;
      state.admin.sepaItem = null;
    },
    clearBankData: (state) => {
      state.user.bankData = initialState.user.bankData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBankData.pending, (state) => {});
    builder.addCase(getBankData.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.bankData = action.payload;
      }
    });
    builder.addCase(getBankData.rejected, (state) => {});
    builder.addCase(getAdminSepa.pending, (state) => {});
    builder.addCase(getAdminSepa.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.itemsCount = action.payload.totalCount;
        state.admin.data = [...action.payload.data];
      }
    });
    builder.addCase(getAdminSepa.rejected, (state) => {});
    builder.addCase(getAdminSepaItemById.pending, (state) => {
      state.admin.isSepaDataLoading = true;
    });
    builder.addCase(getAdminSepaItemById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.sepaItem = action.payload;
      }
      state.admin.isSepaDataLoading = false;
    });
    builder.addCase(getAdminSepaItemById.rejected, (state) => {
      state.admin.isSepaDataLoading = false;
    });
    builder.addCase(updateStatusById.pending, (state) => {
      state.admin.isSepaDataLoading = true;
    });
    builder.addCase(updateStatusById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.sepaItem = action.payload;
      }
      state.admin.isSepaDataLoading = false;
    });
    builder.addCase(updateStatusById.rejected, (state) => {
      state.admin.isSepaDataLoading = false;
    });
    builder.addCase(checkRegulierer.pending, (state) => {});
    builder.addCase(checkRegulierer.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.number = action.payload.number;
        state.user.isReguliererPresent = action.payload.isReguliererPresent;
        if (action.payload.user) {
          state.user.userData = action.payload.user;
        }
      }
    });
    builder.addCase(checkRegulierer.rejected, (state) => {});
  },
});

export const { clearAdminSepaData, clearAdminSepaItem, clearBankData } =
  sepaSlice.actions;

export const sepaReducer = sepaSlice.reducer;
