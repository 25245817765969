import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
  History,
} from "../../components";
import {
  clearAdminReclamationItem,
  getAdminReclamationItemById,
  updateStatusById,
} from "../../store/slices/reclamation";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { statuses } from "../AdminSperrmuelanmeldungen";
import appConfig from "../../util/appConfig";
import transformHexToRgba from "../../util/transformHexToRgba";

const AdminReclamationById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { isReclamationItemDataLoading, reclamationItem } = useAppSelector(
    (state) => state.reclamation.admin
  );

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminReclamationItemById(itemId));
    }
    return () => {
      dispatch(clearAdminReclamationItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  const items = [
    {
      title: "",
      items: [
        {
          rowTitle: "Status",
          rowInfo: statuses[reclamationItem?.status!],
        },
        {
          rowTitle: "Datum (Meldung)",
          rowInfo: reclamationItem
            ? format(
                new Date(reclamationItem?.createdAt),
                "dd.MM.yyyy, HH:mm",
                {
                  locale: de,
                }
              )
            : "",
          isLast: true,
        },
      ],
    },
    {
      title: "Kontaktdaten",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${reclamationItem?.userSurname}, ${reclamationItem?.userName}`,
        },
        {
          rowTitle: "Straße",
          rowInfo: `${reclamationItem?.street}
                    ${reclamationItem?.homeNumber}
                    ${
                      reclamationItem?.hausNrZusatz
                        ? `${reclamationItem?.hausNrZusatz}`
                        : ""
                    }`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${
            reclamationItem?.postCode ? reclamationItem.postCode : ""
          } ${reclamationItem?.place}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: `${
            reclamationItem?.phoneNumber
              ? "+" + reclamationItem?.phoneNumber
              : "-"
          }`,
        },
        {
          rowTitle: "E-Mail",
          rowInfo: `${reclamationItem?.email}`,
          isLast: true,
        },
      ],
    },
    {
      title: "Reklamation",
      items: [
        {
          rowTitle: "Abfallart",
          rowInfo: reclamationItem?.type,
        },
        {
          rowTitle: "Reklamationsgrund",
          rowInfo: reclamationItem?.reclamationReason,
        },
        {
          rowTitle: "Leistungsdatum",
          rowInfo: reclamationItem?.date
            ? format(new Date(reclamationItem?.date), "dd.MM.yyyy", {
                locale: de,
              })
            : "",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <AdminDetailsPageItemName text="Detailinformationen" />
        {isReclamationItemDataLoading ? null : !reclamationItem ? null : (
          <div>
            {items.map((el, idx) => {
              return (
                <AdminDetailInfoItem
                  items={el.items}
                  title={el.title}
                  key={idx}
                />
              );
            })}
            {reclamationItem?.history?.length ? (
              <History history={reclamationItem?.history} />
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text="Offen"
                width="200"
                isOutlined
                outlineColor={
                  reclamationItem?.status === "inWork"
                    ? transformHexToRgba(appConfig.secondaryColor, "0.4")
                    : appConfig.secondaryColor
                }
                textColor={
                  reclamationItem?.status === "inWork"
                    ? transformHexToRgba(appConfig.secondaryColor, "0.4")
                    : appConfig.secondaryColor
                }
                disabled={reclamationItem?.status === "inWork"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateStatusById({
                        id: itemId,
                        status: "inWork",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={
                  reclamationItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                textColor={
                  reclamationItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                disabled={reclamationItem?.status === "declined"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateStatusById({
                        id: itemId,
                        status: "declined",
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminReclamationById;
