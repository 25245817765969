import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";
import { format, isValid } from "date-fns";

export interface ILeerungsdatenEinsichtResponseBody {
  behtyp: string;
  behfarbeid: number;
  behfarbe: string;
  behfraktion: string;
  behgroesseid: number;
  behgroesse: string;
  behvolumen: number;
  behrhythmusid: number;
  behrhythmus: string;
  behserviceid: number;
  behservice: string;
  transpondernr: string;
  inventarnr: string;
  behid: number;
  kav: string;
}

export interface ILeerung {
  // leberechnet?: string | null;
  // ledatum: string;
  // legewichtabgerechnet: number;
  // legewichtoriginal: number;
  // lekfz: string;
  // lestorno?: string | null;
  // lestornotermin?: string;
  // levolumen?: string | null;
  // lexgps: number;
  // leygps: number;
  // transpondernr: string;
  berechnet: null | boolean | number;
  datum: string;
  gewichtabgerechnet: number;
  gewichtoriginal: number;
  kfz: string;
  storno: null | number;
  stornotermin: string;
  transpondernr: string;
  volumen: null | number;
  xkoordinate: number;
  ykoordinate: number;
}

export interface ILeerungsdatenEinsicht {
  client: {
    itemsData: ILeerungsdatenEinsichtResponseBody[];
    isLeerungsdatenEinsichtDataLoading: boolean;
  };
}

const initialState: ILeerungsdatenEinsicht = {
  client: {
    itemsData: [],
    isLeerungsdatenEinsichtDataLoading: false,
  },
};

export const getLeerungsdatenEinsichtInfo = createAsyncThunk(
  "leerungsdatenEinsicht/client/getLeerungsdatenEinsichtInfo",
  async (
    {
      gemeindeNr,
      kassenzeichen,
      objId,
    }: {
      objId: number;
      kassenzeichen: number;
      gemeindeNr: number;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.leerungsdatenEinsicht.get.getLeerungsDaten({
        gemeindeNr,
        kassenzeichen,
        objId,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getLeerungsdatenEinsichtInfoBuId = createAsyncThunk(
  "leerungsdatenEinsicht/client/getLeerungsdatenEinsichtInfoBuId",
  async (
    {
      id,
      endDate,
      startDate,
    }: {
      id: string;
      startDate: string | Date | null;
      endDate: string | Date | null;
    },
    { dispatch }
  ) => {
    try {
      // dispatch(setIsLoading(true));

      const data = await API.leerungsdatenEinsicht.get.getLeerungsDatenInfo({
        id,
        endDate:
          endDate && isValid(new Date(endDate))
            ? format(new Date(endDate), "dd.MM.yyyy")
            : `01.01.${new Date().getFullYear()}`,
        startDate:
          startDate && isValid(new Date(startDate))
            ? format(new Date(startDate), "dd.MM.yyyy")
            : `31.12.${new Date().getFullYear()}`,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      // dispatch(setIsLoading(false));
    }
  }
);

const leerungsdatenEinsichtSlice = createSlice({
  name: "leerungsdatenEinsicht",
  initialState,
  reducers: {
    clearLeerungsdatenEinsichtInfo: (state) => {
      state.client.itemsData = [];
      state.client.isLeerungsdatenEinsichtDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeerungsdatenEinsichtInfo.pending, (state) => {
      state.client.isLeerungsdatenEinsichtDataLoading = true;
    });
    builder.addCase(getLeerungsdatenEinsichtInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.itemsData = action.payload;
      }
      state.client.isLeerungsdatenEinsichtDataLoading = false;
    });
    builder.addCase(getLeerungsdatenEinsichtInfo.rejected, (state) => {
      state.client.isLeerungsdatenEinsichtDataLoading = false;
    });
  },
});

export const { clearLeerungsdatenEinsichtInfo } =
  leerungsdatenEinsichtSlice.actions;

export const leerungsdatenEinsichtReducer = leerungsdatenEinsichtSlice.reducer;
