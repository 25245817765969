import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './style.module.scss';
import exploreArrow from "../../assets/images/exploreArrow.svg";

export const TableLastItem = (props: GridCellProps) => {
  const navigate = useNavigate();
  return (
    <td
      className="k-table-td"
      style={{
        border: "none",
      }}
      key={Math.floor(Math.random() * 1000000000000)}
    >
      <Link
        to={props?.dataItem?.id ? props?.dataItem?.id : ''}
        className={style.detailBtn}
        // onClick={() => {
        //   navigate(props.dataItem.id);
        // }}
      >
        <img src={exploreArrow} alt="info" />
      </Link>
    </td>
  );
};