import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  AdminContentWrapper,
  AdminHeader,
  AdminPlaceholder,
  DeleteConfirmationModal,
  Loader,
  ModalWrapper,
} from "../../components";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import Modal from "./components/Modal";
import ContentComponent from "./components/ContentComponent";
import {
  clearStreets,
  getStreetsWithIgnoreFlag,
  setDataLoading,
  updateStreets,
} from "../../store/slices/admin";

const BlockedStreet = () => {
  const dispatch = useAppDispatch();
  const ignoredStreets = useAppSelector((state) => state.admin.ignoredStreets);
  const isBlockedStreetsContentLoading = useAppSelector((state) => state.admin.isBlockedStreetsContentLoading);
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const isModalActive = useAppSelector(
    (state) => state.app.client.isConfirmationPopupActive
  );

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [isDeleteConfirmationModalActive, setIsDeleteConfirmationModalActive] =
    useState(false);

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = ignoredStreets.map((i) => i.strasseNummer);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: number) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const openDeleteConfirmationModalHandler = () => {
    if (selectedItems.length) {
      dispatch(setIsConfirmationPopupActive(true));
      setIsDeleteConfirmationModalActive(true);
    }
  };

  const clearSelectedItems = () => {
    if (selectedItems.length) {
      const d = selectedItems.map((i) => {
        const blockedNumbers = ignoredStreets.find(
          (item) => item.strasseNummer === i
        )!.blockedHomeNumbers.length
          ? [
              ...ignoredStreets
                .find((j) => j.strasseNummer === +i)!
                .blockedHomeNumbers.map((k) => k._id),
            ]
          : [];
        return {
          id: i.toString(),
          ignore: "active" as "active" | "partially" | "ignore",
          blockedHomeNumbers: blockedNumbers,
        };
      });
      dispatch(updateStreets(d));
      dispatch(setIsConfirmationPopupActive(false));
      setSelectedItems([]);
      setIsDeleteConfirmationModalActive(false);
    }
  };

  useEffect(() => {
    dispatch(getStreetsWithIgnoreFlag());
  }, []);

  useEffect(() => {
    if (!ignoredStreets.length) return;
    if (selectedItems.length === ignoredStreets.length) {
      setAllItemsSelected(true);
    } else {
      setAllItemsSelected(false);
    }
  }, [selectedItems.length, ignoredStreets.length]);

  useEffect(() => {
    return () => {
      dispatch(clearStreets());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader
        clearSelectedItems={openDeleteConfirmationModalHandler}
        hasItemsLength={!!ignoredStreets.length}
        hasSelectedItems={!!selectedItems.length}
        pageTitle={"SPERRMÜLL SCHWARZE LISTE"}
        hasBtnGroup={true}
        btnGroupAddItemText={"Straße sperren"}
        btnGroupRemoveItemsText={"Straße entsperren"}
      />
      <AdminContentWrapper>
        {isBlockedStreetsContentLoading ? null : !ignoredStreets.length ? (
          <AdminPlaceholder
            title="No Blocked Streets Yet"
            subTitle="Straße zur Sperrliste hinzufügen"
            hasBtn={true}
            btnText={"Add a street"}
          />
        ) : (
          <ContentComponent
            areAllItemsSelected={allItemsSelected}
            selectAllItemsHandler={allItemsSelectHandler}
            selectedItems={selectedItems}
            selectItemHandler={selectItemHandler}
          />
        )}
      </AdminContentWrapper>
      {isModalActive ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
            setIsDeleteConfirmationModalActive(false);
          }}
        >
          {isDeleteConfirmationModalActive ? (
            <DeleteConfirmationModal
              removeHandler={clearSelectedItems}
              title={"Straße entsperren"}
              subTitle={
                "Sind Sie sicher, dass Sie die Straße(n) etsperren möchten?"
              }
            />
          ) : (
            <Modal />
          )}
        </ModalWrapper>
      ) : null}
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default BlockedStreet;
