import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import {
  AdminContentWrapper,
  AdminDetailPageHeader,
  Button,
  CustomInput,
  Loader,
} from "../../components";
import appConfig from "../../util/appConfig";
import { Formik } from "formik";
import {
  clearAdminUserItem,
  createAdminUser,
  deleteAdminUser,
  getAdminUserById,
  updateAdminUserById,
} from "../../store/slices/adminUser";
import { setAdminLoader } from "../../store/slices/admin";
import transformHexToRgba from "../../util/transformHexToRgba";
import style from "./style.module.scss";

export interface formInitialValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role?: string;
}

const AdminUserById = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { adminUserItem } = useAppSelector((state) => state.adminUser.admin);

  const initialValues: formInitialValues = {
    email: adminUserItem?.email ?? "",
    firstName: adminUserItem?.firstName ?? "",
    lastName: adminUserItem?.lastName ?? "",
    password: "",
    role: adminUserItem?.role ?? "",
  };

  useEffect(() => {
    if (id) {
      dispatch(getAdminUserById(id));
    }
    return () => {
      dispatch(clearAdminUserItem());
      dispatch(setAdminLoader(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <div className={style.title}>
          {id ? "Benutzerinfo" : "Neuen Benutzer erstellen"}
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              if (id) {
                await dispatch(
                  updateAdminUserById({
                    id,
                    data: values,
                  })
                );
              } else {
                await dispatch(createAdminUser(values));
              }

              navigate("/admin/users");
            } catch (error) {
              console.log("error", error);
            }
          }}
          enableReinitialize
          validateOnMount
          validate={(values) => {
            let err: Record<string, string> = {};

            if (!values.email) {
              err.email = "Dieses Feld ist erforderlich";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              err.email = "Ungültige E-Mail Adresse";
            }

            if (!values.firstName) {
              err.firstName = "Dieses Feld ist erforderlich";
            }
            if (!values.lastName) {
              err.lastName = "Dieses Feld ist erforderlich";
            }
            if (!values.password && !id) {
              err.password = "Dieses Feld ist erforderlich";
            }

            return err;
          }}
        >
          {({ errors, handleSubmit }) => {
            const isDisabled =
              !!errors.email ||
              !!errors.firstName ||
              !!errors.lastName ||
              !!errors.password;

            return (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <CustomInput
                    label="Vorname"
                    name="firstName"
                    type="text"
                    width="48%"
                    placeholder="Bitte einfügen..."
                  />
                  <CustomInput
                    label="Nachname"
                    name="lastName"
                    type="text"
                    width="48%"
                    placeholder="Bitte einfügen..."
                  />
                  <CustomInput
                    label="EMail"
                    name="email"
                    type="text"
                    width="48%"
                    placeholder="Bitte einfügen..."
                  />
                  <CustomInput
                    label="Passwort"
                    name="password"
                    type="password"
                    width="48%"
                    placeholder="Bitte einfügen..."
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "100px",
                  }}
                >
                  <Button
                    text={id ? "Erneuern" : "Erstellen"}
                    width="200"
                    isOutlined
                    outlineColor={
                      isDisabled
                        ? transformHexToRgba(appConfig.mainColor, "0.4")
                        : appConfig.mainColor
                    }
                    textColor={
                      isDisabled
                        ? transformHexToRgba(appConfig.mainColor, "0.4")
                        : appConfig.mainColor
                    }
                    disabled={isDisabled}
                    marginRight="20"
                    // type="submit"
                    onClick={() => {
                      if (isDisabled) return;
                      handleSubmit();
                    }}
                  />
                  {id ? (
                    <Button
                      text="Löschen"
                      width="200"
                      backgroundColor="white"
                      isOutlined
                      outlineColor={"red"}
                      textColor={"red"}
                      marginRight="20"
                      onClick={async () => {
                        if (id) {
                          await dispatch(deleteAdminUser(id));

                          navigate("/admin/users");
                        }
                      }}
                    />
                  ) : null}
                </div>
              </>
            );
          }}
        </Formik>
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminUserById;
