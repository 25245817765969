import React from "react";
import { Button, History } from "../../../../../components";
import appConfig from "../../../../../util/appConfig";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { confirmSperrmuell } from "../../../../../store/slices/sperrmuell";
import transformHexToRgba from "../../../../../util/transformHexToRgba";
import style from "./style.module.scss";
import { statuses } from "../../../../AdminSperrmuelanmeldungen";

const Calw = () => {
  const dispatch = useAppDispatch();
  const { sperrmuelItemInfo } = useAppSelector(
    (state) => state.sperrmuell.admin
  );
  const { itemId } = useParams();

  return (
    <div
      style={
        {
          // display: "flex",
          // justifyContent: "space-between",
        }
      }
    >
      {/* <div className={style.title}>Sperrmüll Information</div>
      <div className={style.tableInfoWrapper}>
        {sperrmuelItemInfo?.status ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Sperrmüllstatus</div>
            <div className={style.rowInfo}>
              {statuses[sperrmuelItemInfo?.status]}
            </div>
          </div>
        ) : null}

        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Sperrmülinfo</div>
          <div className={style.rowInfo}>
            {sperrmuelItemInfo?.trashTypeData?.map((el, idx) => {
              return (
                <div
                  style={{
                    marginBottom:
                      sperrmuelItemInfo.trashTypeData &&
                      idx === sperrmuelItemInfo?.trashTypeData?.length - 1
                        ? "0"
                        : "10px",
                  }}
                >
                  {el.name} - {el.value} Stück
                </div>
              );
            })}
          </div>
        </div>

        <div className={style.lastRowWrapper}>
          <div className={style.rowTitle}>Gesamt Sperrmüllvolumen</div>
          <div className={style.rowInfo}>
            {sperrmuelItemInfo?.trashVolume?.toFixed(2)}
          </div>
        </div>
      </div> */}
      {sperrmuelItemInfo?.history?.length ? (
        <History history={sperrmuelItemInfo?.history} />
      ) : null}
      <div
        style={{
          // textAlign: "end",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          text="Offen"
          width="200"
          isOutlined
          outlineColor={
            sperrmuelItemInfo?.status === "inWork"
              ? transformHexToRgba(appConfig.secondaryColor, "0.4")
              : appConfig.secondaryColor
          }
          textColor={
            sperrmuelItemInfo?.status === "inWork"
              ? transformHexToRgba(appConfig.secondaryColor, "0.4")
              : appConfig.secondaryColor
          }
          marginRight="20"
          disabled={sperrmuelItemInfo?.status === "inWork"}
          onClick={() => {
            if (itemId) {
              dispatch(
                confirmSperrmuell({
                  id: itemId,
                  status: "inWork",
                })
              );
            }
          }}
        />
        <Button
          text="Stornieren"
          width="200"
          backgroundColor="white"
          isOutlined
          outlineColor={
            sperrmuelItemInfo?.status === "declined"
              ? transformHexToRgba("#ff0000", "0.4")
              : "red"
          }
          textColor={
            sperrmuelItemInfo?.status === "declined"
              ? transformHexToRgba("#ff0000", "0.4")
              : "red"
          }
          disabled={sperrmuelItemInfo?.status === "declined"}
          onClick={() => {
            if (itemId) {
              dispatch(
                confirmSperrmuell({
                  id: itemId,
                  accepted: false,
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default Calw;
