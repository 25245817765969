import React, { FC } from "react";
import appConfig from "../../util/appConfig";
import style from "./style.module.scss";
import { useAppSelector } from "../../store";

interface ITitle {
  title: string;
  coloredTitle?: string;
  isColoredTitleFirst?: boolean;
  marginTop?: string;
  marginBottom?: string;
  fontSize?: string;
  textTransform?: "uppercase" | "capitalize";
  letterSpacing?: string;
  mobileFontSize?: string;
  hasHelperPadding?: boolean;
}

export const Title: FC<ITitle> = ({
  title,
  coloredTitle = "",
  marginTop = "0",
  marginBottom = "0",
  fontSize,
  textTransform,
  letterSpacing,
  mobileFontSize = 20,
  isColoredTitleFirst = false,
  hasHelperPadding = false
}) => {
  const { isMobile } = useAppSelector((state) => state.app.client);

  const fontSizeHandler = () => {
    let size;
    if (!isMobile && fontSize) {
      size = fontSize;
    }
    if (isMobile && mobileFontSize) {
      size = mobileFontSize;
    }
    return size;
  };

  const generateTitle = () => {
    switch (true) {
      case coloredTitle?.length && isColoredTitleFirst: {
        return (
          <>
            <span
              style={{
                fontSize: `${fontSizeHandler()}px`,
                color: `${appConfig.titleTextColor} !important`,
              }}
            >
              {coloredTitle}{" "}
            </span>
            <span
              style={{
                fontSize: `${fontSizeHandler()}px`,
              }}
            >
              {title}
            </span>
          </>
        );
      }
      case coloredTitle?.length && !isColoredTitleFirst: {
        return (
          <>
            <span
              style={{
                fontSize: `${fontSizeHandler()}px`,
              }}
            >
              {title}{" "}
            </span>
            <span
              style={{
                fontSize: `${fontSizeHandler()}px`,
                color: `${appConfig.titleTextColor} !important`,
              }}
            >
              {coloredTitle}
            </span>
          </>
        );
      }
      default: {
        return (
          <span
            style={{
              fontSize: `${fontSizeHandler()}px`,
            }}
          >
            {title}
          </span>
        );
      }
    }
  };

  return (
    <h2
      className={style.main_title}
      style={{
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
        textTransform: textTransform,
        letterSpacing: letterSpacing && letterSpacing,
        padding: hasHelperPadding ? '0 15px' : ''
      }}
    >
      {generateTitle()}
    </h2>
  );
};
