import React from "react";
import style from "./style.module.scss";
import { BtnLink } from "../BtnLink";
import appConfig from "../../util/appConfig";

export const NoObjectPlaceholder = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.text}>
        Hier sind noch keine Daten ersichtlich, weil noch kein Objekt mit Ihrem
        Onlinekonto verknüpft wurde. Bitte verknüpfen Sie zunächst ein Objekt.
      </div>
      <BtnLink
        link="/kassenzeichen-verwaltung"
        title="Zur Kassenzeichen Verwaltung"
        color={appConfig.mainColor}
        isOutlined
      />
    </div>
  );
};
