import { IAdminUserResponseBody } from "../../store/slices/adminUser";
import { adminInstance } from "../api";

const getAdminUsers = ({
  signal,
  direction,
  page,
  pageSize,
  searchValue,
  sort,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
}) => {
  return adminInstance
    .get<{ data: IAdminUserResponseBody[]; totalCount: number }>("/admin/users", {
      params: {
        direction,
        page,
        pageSize,
        searchValue,
        sort,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAdminUserById = (id: string) => {
  return adminInstance
    .get<IAdminUserResponseBody>(`/admin/user/${id}`)
    .then((res) => res.data);
};

const createAdminUser = ({
  email,
  firstName,
  lastName,
  password,
  role,
}: {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  role?: string;
}) => {
  return adminInstance
    .post("/admin/user", {
      email,
      firstName,
      lastName,
      password,
      role,
    })
    .then((res) => res.data);
};

const updateAdminUserById = ({
  id,
  data,
}: {
  id: string;
  data: {
    email: string;
    password?: string;
    firstName: string;
    lastName: string;
    role?: string;
  };
}) => {
  return adminInstance.put(`/admin/user/${id}`, data).then((res) => res.data);
};

const deleteAdminUser = (id: string) => {
  return adminInstance.delete(`/admin/user/${id}`).then((res) => res.data);
};

const AdminUsers = {
  get: {
    getAdminUsers,
    getAdminUserById,
  },
  post: {
    createAdminUser,
  },
  put: {
    updateAdminUserById,
  },
  delete: {
    deleteAdminUser,
  },
};

export default AdminUsers;
