import { AddressData } from "../../store/slices/objectVerwaltung";
import { userInstance } from "../api";

const getObjectVerwaltungData = () => {
  return userInstance.get(`/object-verwaltung/data`).then((res) => res.data);
};

const getObjectVerwaltungAddressData = () => {
  return userInstance
    .get<AddressData>(`/object-verwaltung/address-data`)
    .then((res) => res.data);
};

const addNewObject = ({
  homeNumber,
  objectNumber,
  place,
  postCode,
  street,
  homeNumberTo,
  ortsteilNr,
  hausNrZusatz,
  hausNrZusatzTo,
}: {
  homeNumber: string;
  objectNumber: string;
  place: string;
  ortsteilNr: string | number;
  postCode: number;
  street: string;
  homeNumberTo?: string;
  hausNrZusatz?: string;
  hausNrZusatzTo?: string;
}) => {
  return userInstance
    .post(`/object-verwaltung`, {
      homeNumber,
      objectNumber,
      place,
      postCode,
      street,
      homeNumberTo,
      ortsteilNr,
      hausNrZusatz,
      hausNrZusatzTo,
    })
    .then((res) => res.data);
};
const validateBookingCode = ({ bookingCode }: { bookingCode: string }) => {
  return userInstance
    .post(`/object-verwaltung/booking-code`, {
      bookingCode,
    })
    .then((res) => res.data);
};

const ObjectVerwaltung = {
  get: {
    getObjectVerwaltungData,
    getObjectVerwaltungAddressData,
  },
  post: {
    addNewObject,
    validateBookingCode,
  },
};

export default ObjectVerwaltung;
