import React, { useEffect, useState } from "react";
import { ICalendarData } from "../../api/calendar/calendar.api";
import API from "../../api/api";
import {
  AdminContentWrapper,
  AdminHeader,
  AdminPlaceholder,
  TableLastItem,
  Loader,
  ModalWrapper,
  TableGrid,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import {
  clearCalendarData,
  getAdminCalendarData,
} from "../../store/slices/calendar";
import appConfig from "../../util/appConfig";
import Modal from "./components/Modal";
import style from "./style.module.scss";
import { GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import exploreArrow from "../../assets/images/exploreArrow.svg";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { setDataLoading } from "../../store/slices/admin";

const AdminCalendarData = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const calendarData = useAppSelector(
    (state) => state.calendar.admin.calendarData
  );
  const isCalendarDataLoading = useAppSelector(
    (state) => state.calendar.admin.isCalendarDataLoading
  );
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const isModalActive = useAppSelector(
    (state) => state.app.client.isConfirmationPopupActive
  );

  const [selectedItem, setSelectedItem] = useState<null | ICalendarData>(null);
  const [isEditable, setIsEditable] = useState(false);
  const [synchronizingStatus, setSynchronizingStatus] = useState(true);
  const [progress, setProgress] = useState(0);

  // const selectItemHandler = (id: string, isEditable: boolean = false) => {
  //   const item = calendarData.find((i) => i.id === id);
  //   setSelectedItem(item!);
  //   dispatch(setIsConfirmationPopupActive(true));
  //   setIsEditable(isEditable);
  // };

  // const loadMoreItemsHandler = () => {
  //   dispatch(getAdminCalendarData(calendarData.length / 50 + 1));
  // };

  const updateHandler = () => {
    API.admin.get.synchronizeCalendar();
    setSynchronizingStatus(true);
  };

  useEffect(() => {
    return () => {
      dispatch(clearCalendarData());
      dispatch(setDataLoading(false));
    };
  }, []);

  useEffect(() => {
    let startSynchronization: NodeJS.Timeout;
    if (synchronizingStatus) {
      startSynchronization = setInterval(() => {
        API.admin.get.getCityPartsStatus("calendar").then((res) => {
          setSynchronizingStatus(res.status);
          setProgress(res.progress);
          if (!res.status) {
            dispatch(getAdminCalendarData(1));
            clearInterval(startSynchronization);
            setProgress(0);
          }
        });
      }, 2000);
    }
    return () => {
      clearInterval(startSynchronization);
    };
  }, [synchronizingStatus]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader
        pageTitle={"Kalender Daten"}
        hasBtnGroup={false}
        hasSingleBtn={true}
        singleBtnColor={appConfig.mainColor}
        singleBtnText={
          !synchronizingStatus ? "Synchronize data" : `Loading... ${progress}%`
        }
        singleBtnFunc={!synchronizingStatus ? updateHandler : () => {}}
      />
      <AdminContentWrapper>
        {(isCalendarDataLoading || synchronizingStatus) &&
        !calendarData.length ? null : !calendarData.length ? (
          <AdminPlaceholder
            title="No Calendar Data Yet"
            subTitle="Some description placeholder"
            hasBtn={false}
          />
        ) : (
          <TableGrid
            data={calendarData.map((i) => {
              return {
                ...i,
                formattedDate: format(new Date(i.date), "dd.MM.yyyy", {
                  locale: de,
                }),
              };
            })}
            items={[
              {
                field: "place",
                title: "Platz",
              },
              {
                field: "streetDistrict",
                title: "Strasse",
              },
              {
                field: "formattedDate",
                title: "Datum",
              },
              { field: "color", title: "Farbe" },
            ]}
            lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          />
          // <ContentComponent
          //   items={calendarData}
          //   selectItemHandler={selectItemHandler}
          //   selectedItemId={selectedItem?.id || null}
          //   loadMoreItemsHandler={loadMoreItemsHandler}
          // />
        )}
      </AdminContentWrapper>
      {isModalActive && selectedItem ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
            setSelectedItem(null);
            setIsEditable(false);
          }}
        >
          <Modal
            item={selectedItem}
            isEditable={isEditable}
            closeModalHandler={() => {
              dispatch(setIsConfirmationPopupActive(false));
              setIsEditable(false);
            }}
          />
        </ModalWrapper>
      ) : null}
      {(isAdminLoaderActive || synchronizingStatus) && <Loader />}
    </div>
  );
};

export default AdminCalendarData;
