import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
  History,
} from "../../components";
import {
  clearAdminBehaeltergemeinschaftItem,
  getAdminBehaeltergemeinschaftItemById,
  updateAdminBehaeltergemeinschaftItemById,
} from "../../store/slices/behaeltergemeinschaft";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import appConfig from "../../util/appConfig";
import transformHexToRgba from "../../util/transformHexToRgba";

export const statuses: { [key: string]: string } = {
  accepted: "Fertig",
  declined: "Storniert",
  inWork: "In Arbeit",
};

const AdminBehaeltergemeinschaftInfoById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { behaeltergemeinschaftItem, isBehaeltergemeinschaftItemDataLoading } =
    useAppSelector((state) => state.behaeltergemeinschaft.admin);

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminBehaeltergemeinschaftItemById(itemId));
    }
    return () => {
      dispatch(clearAdminBehaeltergemeinschaftItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  let additionalUsers: any = [];

  if (behaeltergemeinschaftItem?.additionalUsers?.length) {
    behaeltergemeinschaftItem?.additionalUsers.forEach((el, idx) => {
      additionalUsers.push({
        title: `Mitnutzer Nr. ${idx + 2}`,
        items: [
          {
            rowTitle: "Name",
            rowInfo: `${el?.surname} ${el?.name}`,
          },
          {
            rowTitle: "Straße",
            rowInfo: `${el?.street} ${el?.homeNumber} ${
              el?.homeNumberZusatz ? ` ${el?.homeNumberZusatz}` : ""
            }`,
          },
          {
            rowTitle: "Ort",
            rowInfo: `${el?.postcode} ${el?.placePart}`,
          },
          {
            rowTitle: "Kassenzeichen",
            rowInfo: `${el?.bookingCode}`,
          },
        ],
      });
    });
  }

  const items = [
    {
      title: "",
      items: [
        {
          rowTitle: "Status",
          rowInfo: behaeltergemeinschaftItem?.status
            ? statuses[behaeltergemeinschaftItem?.status]
            : "",
        },
        {
          rowTitle: "Datum (Meldung)",
          rowInfo: behaeltergemeinschaftItem?.createdAt
            ? format(
                new Date(behaeltergemeinschaftItem.createdAt),
                "dd.MM.yyyy, HH:mm",
                {
                  locale: de,
                }
              )
            : "",
        },
      ],
    },
    {
      title: "Tonnenbesitzer",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${behaeltergemeinschaftItem?.ownerSurname} ${behaeltergemeinschaftItem?.ownerName}`,
        },
        {
          rowTitle: "Straße",
          rowInfo: `${behaeltergemeinschaftItem?.ownerStreet} ${
            behaeltergemeinschaftItem?.ownerHomeNumber
          } ${
            behaeltergemeinschaftItem?.ownerHomeNumberZusatz
              ? ` ${behaeltergemeinschaftItem?.ownerHomeNumberZusatz}`
              : ""
          }`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${behaeltergemeinschaftItem?.ownerPostcode} ${behaeltergemeinschaftItem?.ownerPlacePart}`,
        },
        {
          rowTitle: "Kassenzeichen",
          rowInfo: `${behaeltergemeinschaftItem?.ownerBookingCode}`,
        },
        {
          rowTitle: "E-Mail",
          rowInfo: `${
            behaeltergemeinschaftItem?.email
              ? behaeltergemeinschaftItem?.email
              : ""
          }`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: `${
            behaeltergemeinschaftItem?.phoneNumber
              ? "+" + behaeltergemeinschaftItem?.phoneNumber
              : ""
          }`,
        },
      ],
    },
    {
      title: "Mitnutzer Nr. 1",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${behaeltergemeinschaftItem?.couserSurname} ${behaeltergemeinschaftItem?.couserName}`,
        },
        {
          rowTitle: "Straße",
          rowInfo: `${behaeltergemeinschaftItem?.couserStreet} ${
            behaeltergemeinschaftItem?.couserHomeNumber
          } ${
            behaeltergemeinschaftItem?.couserHomeNumberZusatz
              ? ` ${behaeltergemeinschaftItem?.couserHomeNumberZusatz}`
              : ""
          }`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${behaeltergemeinschaftItem?.couserPostcode} ${behaeltergemeinschaftItem?.couserPlacePart}`,
        },
        {
          rowTitle: "Kassenzeichen",
          rowInfo: `${
            behaeltergemeinschaftItem?.couserBookingCode
              ? behaeltergemeinschaftItem?.couserBookingCode
              : "-"
          }`,
        },
      ],
    },
    ...additionalUsers,
    {
      title: "Behälterinformation",
      items: [
        {
          rowTitle: "Typ",
          rowInfo: `${
            behaeltergemeinschaftItem?.type
              ? behaeltergemeinschaftItem?.type
              : ""
          }`,
        },
        {
          rowTitle: "Tonne",
          rowInfo: ``,
          rowCustomItem: (
            <>
              {behaeltergemeinschaftItem?.ownerTrashBinData.map((el) => {
                return (
                  <div>
                    {el.bin}, Nr. {el.binNumber}
                  </div>
                );
              })}
            </>
          ),
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        {isBehaeltergemeinschaftItemDataLoading ? null : !behaeltergemeinschaftItem ? null : (
          <div>
            <AdminDetailsPageItemName text="Detailinformationen" />
            <div>
              {items.map((el, idx) => {
                return (
                  <AdminDetailInfoItem
                    items={el.items}
                    title={el.title}
                    key={idx}
                  />
                );
              })}
              {behaeltergemeinschaftItem?.history?.length ? (
                <History history={behaeltergemeinschaftItem?.history} />
              ) : null}
            </div>
            <div
              style={{
                // textAlign: "end",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                text="Offen"
                width="200"
                // backgroundColor={appConfig.secondaryColor}
                isOutlined
                outlineColor={
                  behaeltergemeinschaftItem.status === "inWork"
                    ? transformHexToRgba(
                        appConfig.secondaryColor || appConfig.mainColor,
                        "0.4"
                      )
                    : appConfig.secondaryColor || appConfig.mainColor
                }
                textColor={
                  behaeltergemeinschaftItem.status === "inWork"
                    ? transformHexToRgba(
                        appConfig.secondaryColor || appConfig.mainColor,
                        "0.4"
                      )
                    : appConfig.secondaryColor || appConfig.mainColor
                }
                disabled={behaeltergemeinschaftItem.status === "inWork"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminBehaeltergemeinschaftItemById({
                        id: itemId,
                        status: "inWork",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Akzeptieren"
                width="200"
                backgroundColor={
                  behaeltergemeinschaftItem.status === "accepted"
                    ? transformHexToRgba(appConfig.mainColor, "0.4")
                    : appConfig.mainColor
                }
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminBehaeltergemeinschaftItemById({
                        id: itemId,
                        status: "accepted",
                      })
                    );
                  }
                }}
                disabled={behaeltergemeinschaftItem.status === "accepted"}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={
                  behaeltergemeinschaftItem.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                textColor={
                  behaeltergemeinschaftItem.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminBehaeltergemeinschaftItemById({
                        id: itemId,
                        status: "declined",
                      })
                    );
                  }
                }}
                disabled={behaeltergemeinschaftItem.status === "declined"}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminBehaeltergemeinschaftInfoById;
