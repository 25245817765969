import { IAdminNotificationItemsCount } from "../../store/slices/app";
import { adminInstance, instance } from "../api";

const adminLogin = (email: string, password: string) => {
  return instance
    .post<{ token: string; role?: string }>("/admin/login", {
      email,
      password,
    })
    .then((res) => res.data);
};

const checkAdminToken = (token: string) => {
  return instance.get(`/admin/login?token=${token}`).then((res) => res.data);
};

const getCityPartsStatus = (type: "calendar" | "street") => {
  return adminInstance
    .get<{ status: boolean; progress: number }>(
      `/admin/city/status-check?type=${type}`
    )
    .then((res) => res.data);
};

const synchronizeCityParts = () => {
  return adminInstance
    .get(`/admin/city/update-streets`)
    .then((res) => res.data);
};

const synchronizeCalendar = () => {
  return adminInstance
    .get(`/admin/city/update-calendar`)
    .then((res) => res.data);
};

const getPortalSettingsData = () => {
  return adminInstance
    .get<{
      status: boolean;
      title: string;
      subtitle: string;
      url: string;
      bccEMailAddress: string;
      holidays: string[];
    }>(`/admin/portal-settings`)
    .then((res) => res.data);
};

const updateSperrmuellScheduleSettings = (data: {
  status: boolean;
  title: string;
  subtitle: string;
  url: string;
  bccEMailAddress: string;
  holidays: string[];
}) => {
  return adminInstance
    .put<{
      status: boolean;
      title: string;
      subtitle: string;
      url: string;
      bccEMailAddress: string;
      holidays: string[];
    }>(`/admin/portal-settings`, data)
    .then((res) => res.data);
};

const getStreets = (page: number = 0) => {
  return adminInstance
    .get<{ street: string }[]>(`/admin/city/get-streets?page=${page}`)
    .then((res) => res.data);
};

const getStreetsWithIgnoreFlag = () => {
  return adminInstance
    .get<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        ignore: "active" | "partially" | "ignore";
        blockedHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/streets`)
    .then((res) => res.data);
};

const getWhiteListStreets = () => {
  return adminInstance
    .get<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        whiteListIgnore: "active" | "partially" | "ignore";
        whiteListHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/whitelist-streets`)
    .then((res) => res.data);
};

const getGreyListStreets = () => {
  return adminInstance
    .get<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        greyListIgnore: "active" | "partially" | "ignore";
        greyListHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/greylist-streets`)
    .then((res) => res.data);
};

const updateStreets = (
  data: {
    id: string;
    ignore: "active" | "partially" | "ignore";
    blockedHomeNumbers?: string[];
  }[]
) => {
  return adminInstance
    .put<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        ignore: "active" | "partially" | "ignore";
        blockedHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/streets`, data)
    .then((res) => res.data);
};

const updateOneStreet = (data: {
  id: string;
  ignore: "active" | "partially" | "ignore";
  blockedHomeNumbers?: { from: string; to: string }[];
}) => {
  return adminInstance
    .post<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        ignore: "active" | "partially" | "ignore";
        blockedHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/streets`, data)
    .then((res) => res.data);
};

const setWhiteListOfStreets = (data: {
  id: string;
  ignore: "active" | "partially" | "ignore";
  homeNumbers?: { from: string; to: string }[];
}) => {
  return adminInstance
    .post<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        whiteListIgnore: "active" | "partially" | "ignore";
        whiteListHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/whitelist-streets`, data)
    .then((res) => res.data);
};

const setGreyListOfStreets = (data: {
  id: string;
  ignore: "active" | "partially" | "ignore";
  homeNumbers?: { from: string; to: string }[];
}) => {
  return adminInstance
    .post<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        greyListIgnore: "active" | "partially" | "ignore";
        greyListHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/greylist-streets`, data)
    .then((res) => res.data);
};

const updateWhiteListStreets = (
  data: {
    id: string;
    ignore: "active" | "partially" | "ignore";
    whiteListHomeNumbers?: string[];
  }[]
) => {
  return adminInstance
    .put<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        whiteListIgnore: "active" | "partially" | "ignore";
        whiteListHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/whitelist-streets`, data)
    .then((res) => res.data);
};

const updateGreyListStreets = (
  data: {
    id: string;
    ignore: "active" | "partially" | "ignore";
    greyListHomeNumbers?: string[];
  }[]
) => {
  return adminInstance
    .put<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        greyListIgnore: "active" | "partially" | "ignore";
        greyListHomeNumbers: { from: string; to: string; _id: string }[];
      }[]
    >(`/admin/greylist-streets`, data)
    .then((res) => res.data);
};
const getAdminNotificationItemsCount = () => {
  return adminInstance
    .get<IAdminNotificationItemsCount>(`/admin/notifications`)
    .then((res) => res.data);
};

const Admin = {
  get: {
    checkAdminToken,
    getCityPartsStatus,
    synchronizeCityParts,
    getPortalSettingsData,
    synchronizeCalendar,
    getStreets,
    getStreetsWithIgnoreFlag,
    getWhiteListStreets,
    getGreyListStreets,
    getAdminNotificationItemsCount,
  },
  post: {
    adminLogin,
    updateOneStreet,
    setWhiteListOfStreets,
    setGreyListOfStreets,
  },
  put: {
    updateSperrmuellScheduleSettings,
    updateStreets,
    updateGreyListStreets,
    updateWhiteListStreets,
  },
  delete: {},
};

export default Admin;
